import { useContext, useEffect } from "react";
import { matchPath, useLocation, useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import useScrollReset from "hooks/useScrollReset";
import RootContextWrapper from "contexts/rootContext";
import { commonRoute, authRoute, nonAuthRoute, paths } from "routes";
import AuthContext, { restoreContext } from "contexts/authContext";
import GlobalStyles from "./components/GlobalStyles";
import { createTheme } from "./theme";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { userService } from "services/authServices";
import "react-phone-number-input/style.css";

const matchAllPath = (paths, withMatch) => {
  let res =
    paths
      .map((path) => {
        if (path === "*") return false;
        return matchPath(path, withMatch);
      })
      .filter((m) => m).length > 0;
  return res;
};

const App = () => {
  const { auth, changeAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(async () => {
    try {
      if (localStorage.getItem("token")) {
        const res = await userService();
        const oldAuthData = restoreContext();
        changeAuth({
          ...oldAuthData,
          userData: {
            ...oldAuthData.userData,
            user: { ...oldAuthData.userData.user, ..._.get(res, "data", {}) },
          },
        });
      }
    } catch (ex) {
      const isMathched = matchAllPath(
        [
          ...commonRoute[0].children.map((r) => r.path),
          ...nonAuthRoute[0].children.map((r) => r.path),
        ],
        location.pathname
      );
      if (!isMathched) navigate(paths.login);
      changeAuth({ isLoggedIn: false, userData: {} });
    }
  }, []);

  let routes = [];
  if (_.get(auth, "isLoggedIn", false)) {
    routes = [...commonRoute, ...authRoute];
  } else {
    routes = [...commonRoute, ...nonAuthRoute, ...authRoute];
  }
  const content = useRoutes(routes);
  const theme = createTheme();

  useScrollReset();
  return (
    <ThemeProvider theme={theme}>
      <RootContextWrapper>
        <GlobalStyles />
        {content}
        <ToastContainer />
      </RootContextWrapper>
    </ThemeProvider>
  );
};

export default App;
