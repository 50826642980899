import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { PhotoSwipeGallery } from "react-photoswipe";
import _ from "lodash";
import "react-photoswipe/lib/photoswipe.css";

const ImageView = (props) => {
  const { images } = props;
  const [imageList, setImageList] = useState(
    _.sortBy(images, (item) => item.index)
  );

  useEffect(() => {
    setImageList(_.sortBy(images, (item) => item.index));
  }, []);

  let options = {};

  const getThumbnailContent = (item) => {
    return (
      <Box
        display="inline-block"
        position="relative"
        style={{ padding: "3px" }}
      >
        <img
          src={item.src}
          style={{ height: "200px", minHeight: "300px" }}
          onLoad={(e) => {
            const filterIndex = _.filter(
              imageList,
              (i) => i.index === item.index
            );
            const otherIndex = _.filter(
              imageList,
              (i) => i.index !== item.index
            );
            if (filterIndex.length > 0) {
              let newH = e.target.naturalHeight;
              let newW = e.target.naturalWidth;
              const newstate = [
                ...otherIndex,
                {
                  ...filterIndex[0],
                  w: newW,
                  h: newH,
                },
              ];
              if (filterIndex[0].w !== newW && filterIndex[0].h !== newH) {
                setImageList(_.sortBy(newstate, (item) => item.index));
              }
            }
          }}
          alt="product"
        />
        <Box
          p={1}
          className="pos-abs color-white"
          style={{
            background: "rgba(0, 0, 0, 0.35)",
            top: "8px",
            left: "8px",
            fontSize: "12px",
            fontWeight: 700,
            borderRadius: "5px",
          }}
        >
          {item.index} of {imageList.length}
        </Box>
      </Box>
    );
  };

  return (
    <PhotoSwipeGallery
      items={imageList}
      options={options}
      thumbnailContent={getThumbnailContent}
    />
  );
};

export default ImageView;
