import React from "react";
import "./style.css";

const Privacy = () => {
  return (
    <div className="privacy">
      <div className="container">
        <h2>Privacy Policy</h2>
        <p>Last updated: October 13, 2021</p>

        <h3>Overview</h3>
        <p>
          This Privacy Policy ("Privacy Policy") applies to the websites located
          at alertmewhen.com, as well as any other websites owned and operated
          by AlertMeWhen that direct the viewer or user to this Privacy Policy.
        </p>
        <p>
          In this Privacy Policy, the terms "AlertMeWhen," "we," and "us" refers
          to Vested Ventures, Inc. and its respective subsidiaries and
          affiliated companies. Please note that websites that are owned and
          operated by AlertMeWhen may contain links to digital properties that
          are owned and operated by other companies. This Privacy Policy does
          not apply to websites and services that are owned and operated by
          unaffiliated third parties.
        </p>
        <p>
          This Privacy Policy does not apply to any of the practices of eBay or
          Amazon. To understand how eBay or Amazon process your information,
          please refer to the provider’s privacy policy.
        </p>
        <p>
          <strong>
            If you do not agree with our <a>Terms of Service</a> or this Privacy
            Policy, please discontinue use of our websites immediately.
          </strong>
        </p>

        <h3>Contents</h3>
        <p>
          You may skip to the specific sections of our Privacy Policy by
          selecting any of the sections listed below.
        </p>
        <ol>
          <li>
            <a href="#updates">Updates to this Privacy Policy</a>
          </li>
          <li>
            <a href="#info">Information We Collect</a>
          </li>
          <li>
            <a href="#use">How We Use Your Information</a>
          </li>
          <li>
            <a href="#share">How We Share Your Information</a>
          </li>
          <li>
            <a href="#cookies">Cookies, Web Beacons and Similar Technologies</a>
          </li>
          <li>
            <a href="#choices">Your Choices</a>
          </li>
          <li>
            <a href="#protect">How We Protect Your Information</a>
          </li>
          <li>
            <a href="#retention">Retention and Disposal of Your Information</a>
          </li>
          <li>
            <a href="#children">Children’s Privacy</a>
          </li>
          <li>
            <a href="#disclosures">
              Additional Disclosures for California, Colorado or Virginia
              Residents
            </a>
          </li>
          <li>
            <a href="#disclosures2">
              {" "}
              Additional Disclosures for Data Subjects in the EEA, the U.K., and
              Switzerland
            </a>
          </li>
          <li>
            <a href="#contact">Contact Us</a>
          </li>
        </ol>

        <div id="updates"></div>
        <br />
        <br />
        <h3>1. Updates to this Privacy Policy</h3>
        <p>
          This Privacy Policy went into effect on the “Last Updated” date noted
          near the top of this page. We may update this Privacy Policy from time
          to time. We will notify you of any modifications to this Privacy
          Policy that might materially affect your rights or the way that we use
          or disclose your personal information prior to the change becoming
          effective by means of a message on this website. We encourage you to
          periodically review this Privacy Policy to ensure you are familiar
          with the most current version.
        </p>

        <div id="info"></div>
        <br />
        <br />
        <h3>2. Information We Collect</h3>
        <p>
          As you use our websites or visit one of our stores, we collect
          information about you and the services you use. The information we
          collect falls into two main categories: (1) information you
          voluntarily provide us; and (2) information we collect from you
          automatically.
        </p>
        <p>
          Some examples of when we collect this information include when you
          browse one of our websites; link your eBay account while using one of
          our websites; or contact us for customer service.
        </p>
        <p>
          <strong>(a) Information You Give Us</strong>
          <br />
          Some information we collect is provided when you use our services,
          such as when you browse our website, link your eBay account, or
          contact us for customer service inquiries.
        </p>
        <p>
          This may include your first and last name, username, email address,
          purchase or search history, and any other information you give us,
          such as to contact AlertMeWhen customer service.
        </p>
        <p>
          <strong>(b) Information We Automatically Collect</strong>
          <br />
          Device and Usage Information – Like many website operators, we collect
          information that your browser sends whenever you visit our websites.
          This includes Log Data, such as your computer’s IP address, browser
          type, browser version, the pages of our Sites that you visit, the time
          and date of your visit, the time spent on those pages and other
          statistics, and whether you reached our page via a social media or
          email campaign. This information may be collected via several
          technologies, including cookies, web beacons, clear GIFs, canvas
          fingerprinting and other means.
        </p>

        <div id="use"></div>
        <br />
        <br />
        <h3>3. How We Use Your Information</h3>
        <p>
          We use your information for business and commercial purposes,
          including to provide our services to you, to perform customer service
          functions, for security and fraud prevention, and to perform website
          analytics.
        </p>
        <p>
          We may use the information we collect about you in a variety of ways,
          including to:
        </p>
        <p>
          <strong>(a) To Provide Our Services.</strong> We process certain
          personal information when you access or use our services, including
          to:
        </p>
        <ul>
          <li>search eBay by using images; and to</li>
          <li>
            connect our services to your eBay account to search based on your
            past search or purchase history;
          </li>
        </ul>
        <p>
          <strong>(b) To Communicate With You.</strong> We process certain
          information in order to communicate with you in relation to our
          services, and your requests, including to:
        </p>
        <ul>
          <li>
            respond to your customer service inquiries and requests for
            information;
          </li>
        </ul>
        <p>
          <strong>
            (c) For Research, Development, and Improvement of Our Services.
          </strong>{" "}
          We want to ensure that our website and services are continually
          improving and expanding so that we meet and exceed your needs and
          expectations. To do so, we may process certain personal information,
          including to:
        </p>
        <ul>
          <li>
            maintain, improve, and analyze our websites, and the services we
            offer; and,
          </li>
          <li>detect, prevent, or investigate suspicious activity or fraud.</li>
        </ul>
        <p>
          <strong>(d) To Enforce our Terms, Agreements, or Policies.</strong> To
          maintain a safe, secure, and trusted environment for you when you use
          our websites and other services, we use your personal information to
          ensure our terms, policies, and agreements with you and any third
          parties are enforced.
        </p>
        <p>
          <strong>(e) To Comply with Applicable Laws.</strong> We may be
          required to process certain personal information under certain laws
          and regulations, such as tax laws, as well as to:
        </p>
        <ul>
          <li>
            maintain appropriate records for internal administrative purposes;
            and,
          </li>
          <li>
            comply with applicable legal and regulatory obligations, and respond
            to lawful governmental requests, as needed.
          </li>
        </ul>
        <p>
          <strong>(f) With Your Consent.</strong> We may process certain
          personal information in order to fulfill any other business or
          commercial purposes at your direction or with your consent.
        </p>

        <div id="share"></div>
        <br />
        <br />
        <h3>4. How We Share Your Information</h3>
        <p>
          We share your information as needed to fulfill the purposes described
          in this Privacy Policy and as permitted by applicable law. This
          includes sharing with service providers to help perform business
          functions at our direction, sharing with your consent, sharing for
          marketing purposes, sharing as part of corporate transactions, and
          sharing to protect lawful interests.
        </p>
        <p>We may share your information in the following circumstances:</p>
        <p>
          <strong>(a) When We Work with Service Providers.</strong> We may share
          your information with service providers that provide us with support
          services, website hosting, email delivery, or analytics services. We
          strive to contractually prohibit these service providers from
          retaining, using, or disclosing your confidential personal information
          for any purpose other than performing agreed upon services for us.
        </p>
        <p>
          <strong>(b) When We Work on Business Transactions.</strong> If we
          become involved with a merger, corporate transaction or another
          situation involving the transfer of some or all of our business
          assets, we may share your information with business entities or people
          involved in the negotiation or transfer.
        </p>
        <p>
          <strong>
            (c) When Sharing Is Necessary to Protect Safety and Lawful
            Interests.
          </strong>{" "}
          We may disclose your data in response to lawful requests by public
          authorities, including to meet law enforcement requirements. We may be
          under a duty to disclose or share your personal information in order
          to comply with any legal obligation, to enforce or apply our terms and
          conditions and other agreements, to protect our rights, property, or
          safety, or to protect the rights, property, or safety of others. This
          includes exchanging information with other companies and organizations
          for the purposes of fraud protection.
        </p>
        <p>
          <strong>(d) When You Give Consent.</strong> We may share information
          about you with other companies if you give us permission or direct us
          to share the information.
        </p>
        <p>
          <strong>(e) When the Information Does Not Identify You.</strong> We
          may share your information in a way that does not directly identify
          you. For example, we may share information about your use of our
          websites and services in a manner that does not identify you, or we
          may combine information about the nature or frequency of your
          transactions with similar information about other people and share the
          aggregated information for statistical analysis and other business
          purposes.
        </p>

        <div id="cookies"></div>
        <br />
        <br />
        <h3>5. Cookies, Web Beacons and Similar Technologies</h3>
        <p>
          To make our Sites and services work properly, we sometimes place small
          data files called cookies, or similar technology, on your device. A
          cookie is a small text file that a website saves on your computer or
          mobile device when you visit the site. Technologies such as local
          storage allows us to store data locally within the user's browser.
          This enables the website or browser to remember your actions and
          preferences (such as language, search history, or other preferences)
          over a period of time, so you do not have to keep re-entering them
          whenever you come back to the site or browse from one page to another.
        </p>
        <p>
          If you wish to delete the search history data contained in local
          storage of your browser, simply click on the “X” next to your “Recent
          Searches” tab and the local storage data will be deleted.
        </p>
        <p>
          To learn more, please see our{" "}
          <a href="#cookiepolicy">Cookie Policy</a>.
        </p>
        <p>
          <strong>Google Analytics.</strong> We use Google Analytics. We use the
          information we get from Google Analytics only to improve the Sites. We
          do not combine the information collected through the use of Google
          Analytics with personally identifiable information. Please refer to
          Google’s{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          for more information. You may also choose to download the{" "}
          <a
            href="https://support.google.com/analytics/answer/181881"
            target="_blank"
            rel="noreferrer"
          >
            Google Analytics opt-out browser add-on
          </a>
          .
        </p>
        <p>
          You may wish to restrict the use of cookies. Please be aware that some
          of the features of our website may not function correctly if you
          disable cookies.
        </p>
        <p>
          Most modern browsers allow you to change your cookie settings. You can
          usually find these settings in the options or preferences menu of your
          browser. To understand these settings, the following links for
          commonly used browsers may be helpful:
        </p>
        <ul>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666"
              target="_blank"
              rel="noreferrer"
            >
              Cookie settings in Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Cookie settings in Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10"
              target="_blank"
              rel="noreferrer"
            >
              Cookie settings in Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noreferrer"
            >
              Cookie settings in Safari web
            </a>{" "}
            and{" "}
            <a
              href="https://support.apple.com/en-us/HT201265"
              target="_blank"
              rel="noreferrer"
            >
              iOS
            </a>
          </li>
        </ul>

        <div id="choices"></div>
        <br />
        <br />
        <h3>6. Your Choices</h3>
        <p>
          You have control over your personal information, cookie settings, and
          more.
        </p>
        <p>
          <strong>Privacy Rights</strong>
          <br />
          AlertMeWhen believes in putting you in control of your personal
          information. Upon request we will provide you with information about
          whether we hold any of your personal information and how it is used.
          You may access, correct, or remove your personal information by
          contacting us as described in the “Contact Us” section below. As
          required by law for taxes and other record keeping purposes, we may be
          unable to completely delete an account from our internal records.
        </p>
        <p>
          <strong>"Do Not Track" Technology</strong>
          <br />
          Do Not Track ("DNT") is a privacy preference that users can set in
          certain web browsers. DNT is a way for users to inform websites and
          services that they do not want certain information about their webpage
          visits collected over time and across websites or online services. We
          do not recognize or respond to browser-initiated DNT signals, as the
          Internet industry is currently still working toward defining exactly
          what DNT means, what it means to comply with DNT, and a common
          approach to responding to DNT.
        </p>

        <div id="protect"></div>
        <br />
        <br />
        <h3>7. How We Protect Your Information</h3>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information when you enter, submit, or access your
          personal information. For example, when possible, we use encryption to
          transfer and store data. We further limit access to this data using
          access controls and confidentiality commitments.
        </p>
        <p>
          However, no website, application, or transmission can guarantee
          security. Thus, while we have established and maintain what we believe
          to be reasonable procedures to protect the confidentiality, security,
          and integrity of personal information obtained through the websites,
          we cannot ensure or warrant the security of any information you
          transmit to us. You are responsible for protecting your password(s)
          and maintaining the security of your devices.
        </p>

        <div id="retention"></div>
        <br />
        <br />
        <h3>8. Retention and Disposal of Your Information</h3>
        <p>
          AlertMeWhen stores personal information as needed to accomplish the
          purposes identified in this Privacy Policy and to meet legal
          requirements, including record retention, resolving disputes, and
          enforcing our agreements. Our retention of your personal information
          is governed by applicable law. When the information is no longer
          necessary for any of these purposes, we delete it or keep it in a form
          that does not identify you, unless we are required by law to keep this
          information for a longer period. This storage period may extend beyond
          the term of your relationship with AlertMeWhen.
        </p>

        <div id="children"></div>
        <br />
        <br />
        <h3>9. Children’s Privacy</h3>
        <p>
          Our websites are not directed at individuals under the age of 16. We
          do not knowingly collect personal information from children under 16.
          If you become aware that a child has provided us with personal
          information, please contact us using the contact details provided in
          the “Contact Us” section below. If we become aware that a child under
          16 has provided us with personal information, we will take steps to
          delete such information.
        </p>

        <div id="disclosures"></div>
        <br />
        <br />
        <h3>
          10. Additional Disclosures for California, Colorado or Virginia
          Residents
        </h3>
        <p>
          We have listed the privacy rights for several U.S. state jurisdictions
          below, but we understand you may have additional rights in your
          jurisdiction. You may contact us directly at any time about exercising
          your data protection rights. We will consider your request in
          accordance with applicable laws.
        </p>
        <p>
          <strong>Categories of Personal Information</strong>
          <br />
          In the preceding twelve months we may have disclosed these categories
          of personal information for a business purpose:
        </p>
        <ul>
          <li>Identifiers:</li>
          <ul>
            <li>
              Sources of Information – We collect identifiers from the
              information you voluntarily provide to us; and from the
              information we automatically collect, as outlined in this Privacy
              Policy.
            </li>
            <li>
              Purpose of Information Collected – We collect identifiers to
              provide our services; to communicate with you; for development and
              services improvement; and to comply with applicable laws, and as
              otherwise outlined in this Privacy Policy.
            </li>
            <li>
              How we share it – We share identifiers with our service providers;
              to protect lawful interests; and for any other purpose with your
              consent, as otherwise outlined in this Privacy Policy.
            </li>
          </ul>
          <li>
            Personal information under{" "}
            <a
              href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1798.80.&lawCode=CIV"
              target="_blank"
              rel="noreferrer"
            >
              California Civil Code section 1798.80
            </a>
            :
          </li>
          <ul>
            <li>
              Sources of Information – We collect Section 1798.80 information
              from the information you voluntarily provide to us, as outlined in
              this Privacy Policy.
            </li>
            <li>
              Purpose of Information Collected – We collect Section 1798.80
              information for the purposes outlined in this Privacy Policy.
            </li>
            <li>
              How we share it – We share identifiers with our service providers;
              to protect lawful interests; and for any other purpose with your
              consent, as otherwise outlined in this Privacy Policy above.
            </li>
          </ul>
          <li>Commercial information:</li>
          <ul>
            <li>
              Sources of Information – We collect commercial information from
              the information you voluntarily provide to us, as outlined in this
              Privacy Policy.
            </li>
            <li>
              Purpose of Information Collected – We collect commercial
              information to provide our services; for development and services
              improvement; to comply with applicable laws, and as otherwise
              outlined in this Privacy Policy.
            </li>
            <li>
              How we share it – We share commercial information with our service
              providers and AlertMeWhen affiliates, as outlined in this Privacy
              Policy.
            </li>
          </ul>
          <li>Electronic network activity information:</li>
          <ul>
            <li>
              Sources of Information – We collect network information through
              the information you voluntarily provide to us; and from the
              information we automatically collect, as outlined in this Privacy
              Policy.
            </li>
            <li>
              Purpose of Information Collected – We collect network information
              to provide our services; to communicate with you; for development
              and services improvement; and to comply with applicable laws, and
              as otherwise outlined in this Privacy Policy.
            </li>
            <li>
              How we share it – We share network information with our service
              providers; to protect lawful interests; and for any other purpose
              with your consent, as outlined in this Privacy Policy.
            </li>
          </ul>
          <li>Inferences:</li>
          <ul>
            <li>
              Sources of Information – We develop inference data through the
              information you voluntarily provide to us; and from the
              information we automatically collect, as outlined in this Privacy
              Policy.
            </li>
            <li>
              Purpose of Information Collected – We develop inference data to
              provide our services; to communicate with you; for development and
              services improvement; and as otherwise outlined in this Privacy
              Policy.
            </li>
            <li>
              How we share it – We share inference data with our service
              providers and to protect lawful interests, as outlined in this
              Privacy Policy.
            </li>
          </ul>
        </ul>
        <p>
          <strong>We Do Not Sell Your Information</strong> – In the preceding
          twelve months we have not sold personal information.
        </p>
        <p>
          <strong>Your Rights</strong>
        </p>
        <p>
          <strong>
            Access to Specific Information and Data Portability Rights
          </strong>{" "}
          – Subject to certain exceptions, you have the right to request a copy
          of the personal information that we collected about you during the 12
          months before your request. Once we receive your request and verify
          your identity, we will disclose to you:
        </p>
        <ul>
          <li>
            The categories of personal information we have collected about you;
          </li>
          <li>
            The categories of sources for the personal information we have
            collected about you;
          </li>
          <li>
            Our business or commercial purpose for the information collection;
          </li>
          <li>
            The categories of third parties with whom we share that personal
            information;
          </li>
          <li>
            The specific pieces of personal information we collected about you.
          </li>
        </ul>
        <p>
          <strong>Deletion Request Rights</strong> – You have the right to
          request that we delete any of your personal information that we
          collected from you and retained, subject to certain exceptions.
        </p>
        <p>
          <strong>Opting Out</strong> – Residents of certain states have the
          right to opt-out of having their personal information sold. We do not
          sell your personal information.
        </p>
        <p>
          <strong>Non-Discrimination</strong> – Residents of certain states may
          not receive discriminatory treatment by the business for exercising
          your privacy rights.
        </p>
        <p>
          <strong>
            Exercising Access, Data Portability, and Deletion Rights
          </strong>{" "}
          – To exercise the access, data portability, and deletion rights
          described above as a California resident, please submit a verifiable
          consumer request to us by contacting us using the contact details
          below. Please note that to verify your request, we may require you to
          verify personal information that we already have on file for you or to
          provide additional information.
        </p>
        <p>
          <strong>Use of an Authorized Agent to Submit a Request</strong> – Only
          you or a person you formally authorize to act on your behalf, may make
          a verifiable consumer request related to your personal information. If
          you use an authorized agent to submit such a request, we will require
          written proof that the authorized agent has been authorized to act on
          your behalf or a copy of the power-of-attorney document granting that
          right.
        </p>
        <p>
          <strong>Direct Marketing</strong> – If you are a California resident,
          you can request a notice disclosing the categories of personal
          information businesses have shared with third parties for the third
          parties’ direct marketing purposes. We do not share your personal
          information with third parties for the third parties’ direct marketing
          purposes.
        </p>

        <div id="disclosures2"></div>
        <br />
        <br />
        <h3>
          11. Additional Disclosures for Data Subjects in the EEA, the U.K., and
          Switzerland
        </h3>
        <p>
          Residents in the European Economic Area, the United Kingdom, and
          Switzerland (together, for purposes of this section of the Privacy
          Policy, “EEA”), as well as certain other jurisdictions, are entitled
          to certain rights and disclosures with respect to their personal
          information.
        </p>
        <p>
          This section provides general information about how AlertMeWhen
          collects, stores, uses, transfers, and otherwise processes personal
          data in or from certain countries in the EEA, in accordance with the
          General Data Protection Regulation (GDPR) and its local
          implementations. This section does not supersede the applicability of
          any privacy policy found on the website of an EEA-based AlertMeWhen
          affiliate with whom you have engaged, as that entity would be the data
          controller with respect to your personal data and the entity to which
          your data subject rights requests should be directed.
        </p>
        <p>
          <strong>Lawful Bases of Processing</strong> – Where a AlertMeWhen
          entity is acting as a data controller that determines the purposes and
          means of processing your personal data, such as when we collect, use,
          and share personal data as described in the “Information We Collect,”
          “How We Use Your Information,” and “How We Share Your Information”
          sections above, we must have a lawful basis for processing or doing
          so. Our lawful bases for processing personal data include:
        </p>
        <ul>
          <li>for our legitimate business purposes, including to:</li>
          <ul>
            <li>
              respond to your customer service inquiries and requests for
              information;
            </li>
            <li>
              maintain, improve, and analyze our websites, and the services we
              offer;
            </li>
            <li>
              detect, prevent, or investigate security breaches or fraud; and
            </li>
            <li>facilitate the functionality of our websites;</li>
          </ul>
          <li>
            to comply with our legal obligations, for example to maintain
            appropriate records for internal administrative purposes and as
            required by applicable law; and
          </li>
          <li>on the basis of your consent.</li>
        </ul>
        <p>
          You can withdraw your consent at any time by contacting us as
          described in the “Contact Us” section below.
        </p>
        <p>
          <strong>Your Data Subject Rights</strong> – In some countries, and in
          particular if you are located in the EEA, you may have the right to:
        </p>
        <ul>
          <li>Access your personal information;</li>
          <li>Correct inaccurate personal information;</li>
          <li>
            Request erasure of your personal information without undue delay;
          </li>
          <li>
            Request the restricted processing of your personal information;
          </li>
          <li>
            Request portability of the personal information that you have given
            us; and
          </li>
          <li>To object to the processing of your personal information.</li>
        </ul>
        <p>
          To exercise any of these rights, please contact us as set forth in the
          “Contact Us” section below and specify which privacy right(s) you wish
          to exercise. We must verify your identity in order to honor your
          request, which we will respond to within 30 days of receipt.
        </p>
        <p>
          <strong>Retention</strong> – As described in the “Retention and
          Disposal of Your Information” section above, as a general rule, we
          retain information from or about you for so long as necessary to
          fulfill the purposes outlined in this Privacy Policy. When the
          information is no longer necessary for these purposes, we delete it or
          keep it in a form that does not identify you, unless we are required
          by law to keep this information for a longer period.
        </p>
        <p>
          <strong>Transfers Outside the EEA</strong> – Your personal information
          may be transferred to, stored, and processed in a country other than
          the one in which it was collected, including the United States. It may
          also be processed by staff operating outside the EEA who work for
          AlertMeWhen or for our third-party service providers. This may entail
          a transfer of your personal information across international borders.
          The data protections standards may differ and be lower than the
          standards enforced in your jurisdiction.
        </p>
        <p>
          <strong>Complaints</strong> – If you have any issues with our
          compliance, you have the right to lodge a complaint with an{" "}
          <a
            href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
            target="_blank"
            rel="noreferrer"
          >
            EEA supervisory authority
          </a>
          . We would, however, appreciate the opportunity to first address your
          concerns and would welcome you directing an inquiry first to us per
          the “Contact Us” section below.
        </p>

        <div id="contact"></div>
        <br />
        <br />
        <h3>12. Contact Us</h3>
        <p>
          If you wish to contact us or have any questions about or complaints in
          relation to this Privacy Policy, please contact us at the following
          contact details:
        </p>
        <br />
        <p>Telephone: 716-400-3315</p>
        <p>Address: 92 Richmond Avenue, Buffalo, NY 14222</p>

        <br id="cookiepolicy" />
        <hr />
        <br />
        <h2>AlertMeWhen Cookie Policy</h2>
        <p>
          To make this site work properly, we sometimes place small data files
          called cookies, or similar technology, on your device. A cookie is a
          small text file that a website saves on your computer or mobile device
          when you visit the site. Technologies such as local storage allows us
          to store data locally within the user's browser. This enables the
          website or browser to remember your actions and preferences (such as
          language, search history, or other preferences) over a period of time,
          so you do not have to keep re-entering them whenever you come back to
          the site or browse from one page to another.
        </p>

        <h3>Cookies on alertmewhen.com</h3>
        <p>
          The following table sets out the different categories of cookies that
          our site uses, and why we use them. The list of third-party cookie
          providers are intended merely as illustrative and should not be viewed
          as a comprehensive list.
        </p>

        <table className="table table-bordered">
          <tr>
            <th>Types of Cookie</th>
            <th>Purpose</th>
            <th>
              Who Serves
              <br />
              (for example)
            </th>
          </tr>
          <tr>
            <td>Performance/Analytics</td>
            <td>
              These cookies are used to analyze the way our website works and
              how we can improve it. For example, we may use cookies to
              understand how you are using certain site features.
            </td>
            <td>
              <ul>
                <li>
                  <a
                    href="https://policies.google.com/technologies/cookies?hl=en-US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google
                  </a>
                </li>
                <li>
                  <a
                    href="https://newrelic.com/termsandconditions/cookie-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    New Relic
                  </a>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Functionality</td>
            <td>
              These cookies help to enhance your experience by remembering
              choices you have made concerning the features of the website
              (e.g., username, language, search history).
            </td>
            <td>
              <ul>
                <li>AlertMeWhen</li>
              </ul>
            </td>
          </tr>
        </table>

        <h3>Cookies and You </h3>
        <p>
          If you choose not to enable cookies, or delete local storage, you will
          still be able to browse our website, but it will restrict some of the
          functionality of our website or what you can do.
        </p>

        <h3>Controlling your cookies</h3>
        <p>
          You can control cookies in your browser to enable or disable them. If
          you wish to restrict or block the cookies which are set by any
          website, you should do this through the web browser settings for each
          web browser you use, on each device you use to access the internet.
        </p>
        <p>
          If you wish to delete the search history data contained in local
          storage of your browser, simply click on the “X” next to your “Recent
          Searches” tab and the local storage data will be deleted.
        </p>
        <p>
          You can also manage many companies’ cookies used for online
          advertising via the consumer choice tools created under
          self-regulation programs in many countries, such as the US-based{" "}
          <a
            href="https://www.aboutads.info/choices/"
            target="_blank"
            rel="noreferrer"
          >
            aboutads.info choices
          </a>{" "}
          page or the EU-based{" "}
          <a
            href="http://www.youronlinechoices.com/uk/your-ad-choices"
            target="_blank"
            rel="noreferrer"
          >
            Your Online Choices
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Privacy;
