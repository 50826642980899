import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { resendEmailConfirmation } from "services/authServices";

const MailActivationLink = () => {
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");

  return (
    <Formik
      initialValues={{
        username: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required("Username is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resendEmailConfirmation({ username: values.username });
          setMsg("Check your email for new link.");
          setShowMsg(true);
        } catch (err) {
          console.error(err.response.data);
          setStatus({ success: false });
          const errorMsg = JSON.stringify(
            _.map(Object.keys(err.response.data), (key) =>
              _.map(err.response.data[key]).join(", ")
            ).join(", ")
          );
          setErrors({
            submit: errorMsg,
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <>
          {!showMsg && (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                autoFocus
                error={Boolean(touched.username && errors.username)}
                fullWidth
                helperText={touched.username && errors.username}
                label="Username"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                variant="outlined"
              />
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 3 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Resend Link
                </Button>
              </Box>
            </form>
          )}
          {showMsg && (
            <Typography
              variant="h5"
              sx={{
                color: "#28a745",
              }}
            >
              {msg}
            </Typography>
          )}
        </>
      )}
    </Formik>
  );
};

export default MailActivationLink;
