import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core";
import { paths } from "routes";
import MailActivationLink from "components/MailActivationLink";
import { logo } from "assets";

const ResendMailActivation = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: 10 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <RouterLink to={paths.root} style={{ textDecoration: "none" }}>
              <Typography color="textPrimary" gutterBottom variant="h1">
                <img src={logo} width="300px" alt="logo" />
              </Typography>
            </RouterLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          />
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Resend Email Confirmation
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Tell us your username so we can send you a new email
                    confirmation link
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <MailActivationLink />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ResendMailActivation;
