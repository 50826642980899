import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { getNotification } from "services/ebayServices";
import _ from "lodash";
import { paths } from "routes";

const Instant = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const getAndSetAllNote = async () => {
    let res = await getNotification({
      notification_uuid: uuid,
    }).catch((e) => {});
    if (res && res.data) {
      navigate(
        paths.productDetails.replace(
          ":productId",
          _.get(res, "data.data.item_id", "")
        )
      );
    }
  };

  useEffect(() => {
    getAndSetAllNote();
  }, []);

  return <></>;
};

export default Instant;
