import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import SearchTitle from "components/SearchTitle";
import { searchService } from "services/ebayServices";
import _ from "lodash";
import ProductListWithRightScroll from "components/ProductListWithRightScroll";

const MySaveSearchItem = (props) => {
  const { data, deleteCallback } = props;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getAndSetSearchResult = async () => {
    setLoading(true);
    let res = await searchService({
      ..._.get(data, "search_data", {}),
      keywords: _.get(data, "tracker_name", ""),
      paginationInput: {
        entriesPerPage: 20,
        pageNumber: 1,
      },
      sortOrder: "StartTimeNewest",
    });
    if (res.data) {
      setProducts([..._.get(res, "data.searchResult.item", [])]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAndSetSearchResult();
  }, [data]);

  return (
    <>
      <SearchTitle
        data={data}
        deleteCallback={deleteCallback}
        refreshAll={props.refreshAll}
      />
      {loading && (
        <Box mb={2}>
          <CircularProgress size={16} /> &nbsp; Loading...
        </Box>
      )}
      {!loading && (
        <ProductListWithRightScroll
          key={data.tracker_name}
          newListData={products}
        />
      )}
    </>
  );
};

export default MySaveSearchItem;
