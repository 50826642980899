import { useNavigate } from "react-router-dom";
import { Box, useTheme, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { paths } from "routes";
import _ from "lodash";

const DigestTitle = (props) => {
  const { data, stopRedirect, callBack } = props;

  const searchKey = _.get(data, "subscription_name", "");

  const theme = useTheme();
  const navigate = useNavigate();

  const nonEditView = (
    <Box p={1} pl={0} display="flex">
      <Box minWidth={24}>
        <SearchIcon style={{ marginTop: "3px" }} fontSize="medium" />
      </Box>
      <Typography variant="h6" noWrap color="textPrimary">
        <span
          style={{ color: theme.palette.primary.main }}
          onClick={() => {
            !stopRedirect
              ? navigate(paths.search.replace(":searchKey", searchKey))
              : callBack && callBack(data.title);
          }}
          className="searchLink"
        >
          {searchKey}
        </span>{" "}
        {/* - {data.type} TODO -Waiting for the backend to add newly selected item flag */}
      </Typography>
    </Box>
  );

  return nonEditView;
};

export default DigestTitle;
