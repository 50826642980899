import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import _ from "lodash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import style from "./style.js";
import { getAllCategories } from "services/ebayServices.js";

const CategoriesList = (props) => {
  const [parentId, setParentId] = useState(-1);
  const [newCurrentCategory, setNewCurrentCategory] = useState({});
  const { activeCategoryId, categoryFilterList, setActiveCategoryId } = props;
  const classes = style();

  const getAndSetCategoryDetails = async () => {
    if (activeCategoryId !== -1) {
      const res = await getAllCategories({
        category_id: activeCategoryId,
      });
      const arr = _.get(
        res,
        "data.GetCategoryInfoResponse.CategoryArray.Category",
        []
      );
      if (_.isArray(arr)) {
        const category = _.filter(
          arr,
          (item) => item.CategoryID === activeCategoryId
        );
        let parentId = +_.get(category, "[0].CategoryParentID", "-1");
        setParentId(parentId);
        setNewCurrentCategory(_.get(category, "[0]", {}));
      } else {
        if (_.get(arr, "CategoryID", "-") === activeCategoryId) {
          let parentId = +_.get(arr, "CategoryParentID", "-1");
          setParentId(parentId);
          setNewCurrentCategory(arr);
        }
      }
    }
  };

  useEffect(() => {
    getAndSetCategoryDetails();
  }, [activeCategoryId]);

  let categoryList = categoryFilterList;

  if (activeCategoryId !== -1 && categoryFilterList.length === 1) {
    categoryList = categoryFilterList[0].childCategoryHistogram;
  }

  return (
    <>
      <Box mt={2} className={classes.categoryFilter}>
        {activeCategoryId === -1 && (
          <Typography
            component="span"
            variant="subtitle1"
            fontWeight="bold"
            className="category-item"
          >
            All categories &nbsp;
          </Typography>
        )}

        {activeCategoryId !== -1 && (
          <Typography
            component="span"
            variant="subtitle1"
            fontWeight="bold"
            className="category-item"
          >
            {(parentId > -1 || activeCategoryId !== -1) && (
              <span style={{ float: "left" }} title="Back">
                <ArrowBackIcon
                  fontSize="small"
                  onClick={() => setActiveCategoryId(parentId)}
                  style={{ marginBottom: "-4px" }}
                  title="Back"
                />
                &nbsp;
              </span>
            )}{" "}
            <span
              className="inner-span"
              style={{ cursor: "unset" }}
              title={_.get(
                categoryFilterList,
                "[0].categoryName",
                _.get(newCurrentCategory, "CategoryName", "")
              )}
            >
              {_.get(
                categoryFilterList,
                "[0].categoryName",
                _.get(newCurrentCategory, "CategoryName", "")
              )}
            </span>
          </Typography>
        )}
        {_.map(_.slice(categoryList, 0, 19), (category) => (
          <Typography
            component="span"
            variant="subtitle1"
            className="category-item"
            onClick={() =>
              setActiveCategoryId(_.get(category, "categoryId", ""))
            }
          >
            <span
              className="inner-span"
              title={`${_.get(category, "categoryName", "")} (${_.get(
                category,
                "count",
                0
              )})`}
            >
              {`${_.get(category, "categoryName", "")} (${_.get(
                category,
                "count",
                0
              )})`}
            </span>
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default CategoriesList;
