import axiosInstance from "lib/axios";
import Configs from "configs";
import _ from "lodash";

const loginService = async (data) => {
  return await axiosInstance.post(Configs.endpoints.auth.login, data);
};

const logoutService = async () => {
  return await axiosInstance
    .post(Configs.endpoints.auth.logout)
    .catch((error) => {
      console.error(error);
    });
};

const userService = async () => {
  return await axiosInstance.get(Configs.endpoints.auth.user).catch((error) => {
    console.error(error);
    throw error;
  });
};

const registerService = async (data) => {
  return await axiosInstance.post(Configs.endpoints.auth.register, data);
};

const mailActivationService = async (data) => {
  return await axiosInstance.post(
    Configs.endpoints.auth.mailActivation
      .replace("{uid}", _.get(data, "uid", "-"))
      .replace("{token}", _.get(data, "token", "-"))
  );
};

const resetPasswordService = async (data) => {
  return await axiosInstance.post(Configs.endpoints.auth.resetPassword, data);
};

const changePasswordService = async (data) => {
  return await axiosInstance.post(Configs.endpoints.auth.changePassword, data);
};

const resendEmailConfirmation = async (data) => {
  return await axiosInstance.post(
    Configs.endpoints.auth.resendEmailConfirmation,
    data
  );
};

const changeEmail = async (newMail) => {
  return axiosInstance.put(Configs.endpoints.auth.changeEmail, {
    new_email: newMail,
  });
};

const sendOtp = async (phoneNumber) => {
  return axiosInstance.put(Configs.endpoints.auth.sendOtp, {
    phone: phoneNumber,
  });
};

const confirmPhoneNumber = async (phoneNumber, otp) => {
  return axiosInstance.post(Configs.endpoints.auth.confirmPhoneNumber, {
    phone_number: phoneNumber,
    code: otp,
  });
};

const getSmsCredit = async (userId, userEmail) => {
  return axiosInstance.post(Configs.endpoints.auth.getSmsCredit, {
    user_id: userId,
    user_email: userEmail,
    cart_items: [
      {
        name: "Instant Alert Credits",
        quantity: 1,
        currency: "usd",
        amount: "1000",
      },
    ],
  });
};

const getCard = async () => {
  return axiosInstance.get(Configs.endpoints.auth.getCard);
};

const addCard = async (paymentID) => {
  return axiosInstance.post(Configs.endpoints.auth.addCard, {
    paymentID: paymentID,
  });
};

const removeCard = async () => {
  return axiosInstance.get(Configs.endpoints.auth.removeCard);
};

const addSubscribe = async () => {
  return axiosInstance.post(Configs.endpoints.auth.addSubscribe, {});
};

const cancelSubscription = async () => {
  return axiosInstance.post(Configs.endpoints.auth.cancelSubscription, {});
};

export {
  loginService,
  logoutService,
  userService,
  registerService,
  mailActivationService,
  resetPasswordService,
  changePasswordService,
  resendEmailConfirmation,
  changeEmail,
  sendOtp,
  confirmPhoneNumber,
  getSmsCredit,
  addCard,
  getCard,
  removeCard,
  addSubscribe,
  cancelSubscription,
};
