import { Box, Container, Grid, Typography, Hidden } from "@material-ui/core";
import { pricingLight } from "assets";

const Pricing = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.paper",
          minHeight: "100%",
          pb: 6,
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.default",
            py: 6,
          }}
        >
          <Container maxWidth="lg">
            <Grid container alignItems="center" spacing={2} flexWrap="nowrap">
              <Grid item md={7} xs={12}>
                <Typography color="textPrimary" variant="h3">
                  Start today. Boost up your eBay shopping experience!
                </Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item md={5}>
                  <Box
                    sx={{
                      maxWidth: 419,
                      width: "100%",
                    }}
                  >
                    <img
                      alt="Pricing Hero"
                      src={pricingLight}
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Pricing;
