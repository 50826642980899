import { useContext, useEffect, useState, Fragment } from "react";
import { Box, Divider, CircularProgress, Typography } from "@material-ui/core";
import MySearch from "components/MySearch";
import { getAllSubscription } from "services/ebayServices";
import AuthContext from "contexts/authContext";
import MySaveSearchItem from "components/MySaveSearchItem";
import _ from "lodash";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [allSearch, setAllSearch] = useState([]);
  const [hideList, setHideList] = useState([]);
  const { auth } = useContext(AuthContext);

  const getAndSetAllSearch = async () => {
    if (_.get(auth, "userData.user.username", "")) {
      let res = await getAllSubscription({
        username: _.get(auth, "userData.user.username", ""),
      }).catch((e) => {});
      if (res && res.data) {
        setAllSearch(res.data);
      }
    }
  };

  useEffect(async () => {
    setLoading(true);
    await getAndSetAllSearch();
    setLoading(false);
  }, [auth.isLoggedIn]);

  return (
    <>
      <MySearch searchCount={allSearch.length} />
      {loading && (
        <Box m={5} textAlign="center">
          <CircularProgress />
          <Box>Hang on tight we are loading your saved search...</Box>
        </Box>
      )}
      {!loading &&
        _.map(allSearch, (item) => (
          <Fragment key={item.subscription_id}>
            <Box>
              {hideList.indexOf(item.subscription_id) < 0 && (
                <MySaveSearchItem
                  data={item}
                  refreshAll={() => getAndSetAllSearch()}
                  deleteCallback={(deletedData) => {
                    const newList = _.filter(allSearch, (item) => {
                      return (
                        _.get(item, "subscription_id", "") !==
                        _.get(deletedData, "subscription_id", "")
                      );
                    });
                    setAllSearch([...newList]);
                    setHideList([
                      ...hideList,
                      _.get(deletedData, "subscription_id", ""),
                    ]);
                  }}
                />
              )}
            </Box>
            <Divider />
          </Fragment>
        ))}
      {!loading && allSearch.length === 0 && (
        <Box m={3}>
          <Typography variant="h6">You don't have any saved search</Typography>
          <Box mt={1}>
            You can save your search, and schedule notification for new listing
          </Box>
        </Box>
      )}
    </>
  );
};

export default Home;
