import { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";

const PhoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      label="Phone Number"
      name="PhoneNumber"
      variant="outlined"
    />
  );
};
export default forwardRef(PhoneInput);
