import { useContext, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CogIcon from "icons/Cog";
import { logoutService } from "services/authServices";
import AuthContext from "contexts/authContext";
import { paths } from "routes";
import _ from "lodash";

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { auth, changeAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    setLoading(true);
    await logoutService();
    changeAuth({ isLoggedIn: false, userData: {} });
    navigate(paths.login);
    setLoading(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={""}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box p={2}>
          <Typography color="textPrimary" variant="subtitle2"></Typography>
          <Typography
            className="txt-cap"
            color="textSecondary"
            variant="subtitle2"
          >
            {_.get(auth, "userData.user.username", "")}
          </Typography>
        </Box>
        <Divider />
        <Box mt={2}>
          <MenuItem component={RouterLink} to={paths.setting}>
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Settings
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box p={2}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {loading ? <CircularProgress size={24} /> : "Logout"}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
