import { useContext, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import _ from "lodash";
import { registerService } from "services/authServices";
import useIsMountedRef from "hooks/useIsMountedRef";
import AuthContext from "contexts/authContext";
import { useNavigate } from "react-router-dom";
import { paths } from "routes";

const RegisterCard = (props) => {
  const isMountedRef = useIsMountedRef();
  const { auth, changeAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (_.get(auth, "isLoggedIn", false)) navigate(paths.root);
  }, [auth]);

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        password: "",
        policy: false,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        name: Yup.string().max(255).required("Name is required"),
        password: Yup.string().min(8).max(255).required("Password is required"),
        policy: Yup.boolean().oneOf([true], "This field must be checked"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (registerService) {
            const result = await registerService({
              email: values.email,
              username: values.name,
              password: values.password,
            });
            if (result.data) {
              changeAuth({ isLoggedIn: true, userData: result.data });
              navigate(paths.root);
            }
          }
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (error) {
          console.error(error.response.data);
          setStatus({ success: false });
          setErrors({
            submit: JSON.stringify(
              _.map(Object.keys(error.response.data), (key) =>
                _.map(error.response.data[key]).join(", ")
              ).join(", ")
            ),
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label="User Name"
            margin="normal"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              ml: -1,
              mt: 2,
            }}
          >
            <Checkbox
              checked={values.policy}
              color="primary"
              name="policy"
              onChange={handleChange}
            />
            <Typography color="textSecondary" variant="body2">
              I have read the{" "}
              <Link color="primary" component="a" href="#">
                Terms and Conditions
              </Link>
            </Typography>
          </Box>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>{errors.policy}</FormHelperText>
          )}
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Register
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RegisterCard;
