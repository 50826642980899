const URI = "https://api.alertmewhen.com";

const config = Object.freeze({
  endpoints: {
    auth: {
      login: `${URI}/api/auth/login`,
      register: `${URI}/api/auth/register`,
      mailActivation: `${URI}/api/activate/{uid}/{token}`,
      user: `${URI}/api/auth/user`,
      logout: `${URI}/api/auth/logout`,
      resetPassword: `${URI}/api/auth/password_reset/`,
      changePassword: `${URI}/api/auth/password_reset/confirm/`,
      resendEmailConfirmation: `${URI}/api/activation/link`,
      changeEmail: `${URI}/api/auth/email`,
      sendOtp: `${URI}/api/auth/phone`,
      confirmPhoneNumber: `${URI}/api/auth/phone/verify`,
      getSmsCredit: `${URI}/pay/create-checkout-session`,
      getCard: `${URI}/pay/get-card`,
      addCard: `${URI}/pay/add-card`,
      removeCard: `${URI}/pay/remove-card`,
      addSubscribe: `${URI}/pay/subscribe`,
      cancelSubscription: `${URI}/pay/cancel-subscription`,
    },
    ebay: {
      getDigest: `${URI}/api/digest`,
      getDigestNotification: `${URI}/api/notification/digest`,
      getItemNotification: `${URI}/api/notification/item`,
      editDigest: `${URI}/api/digest`,
      search: `${URI}/api/ebay/search`,
      editSearch: `${URI}/api/ebay/edit/subscription`,
      saveSearch: `${URI}/api/ebay/subscribe`,
      autoSuggest: `${URI}/api/ebay/auto/suggest`,
      getAllSubscription: `${URI}/api/ebay/user/subscriptions`,
      deleteSubscription: `${URI}/api/ebay/delete/subscription`,
      notificationSchedule: `${URI}/api/ebay/notify/schedule`,
      getAllCategories: `${URI}/api/ebay/category`,
      getItem: `${URI}/api/ebay/item`,
    },
  },
  stripePublicKey:
    "pk_test_51Js61PFEDx19JpMUmy3twu6BGpbzXnwtVxnvFPezduTVHLqC4u4oCBp9mN1GJ8rK9eY7lf4b57cuolgOP0p7veBv00b5IaXYb8",
});

export default config;
