import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  categoryFilter: {
    "& .category-item": {
      padding: "0px 5px",
      marginRight: "10px",
      cursor: "pointer",
      position: "relative",
      display: "block",
      float: "left",
      ["@media (max-width:750px)"]: {
        fontSize: "12px",
        maxWidth: "150px",
        padding: "0px 3px",
        "& .inner-span": {
          maxWidth: "170px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          position: "relative",
          display: "block",
        },
      },
      ["@media (max-width:410px)"]: {
        maxWidth: "150px",
        padding: "0px 0px",
        "& .inner-span": {
          maxWidth: "120px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          position: "relative",
          display: "block",
        },
      },
    },
    "& .category-item:hover": {
      background: "#eee",
    },
  },
}));
