import { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
  Link,
} from "@material-ui/core";
import { gotoLogIn, hasToken } from "utils";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import { paths } from "routes";
import { SlideTransition } from "components/SlideTransition";

const NotificationPreferencesDialog = (props) => {
  const { children } = props;
  const [open, setOpen] = useState(false);
  const { auth } = useContext(AuthContext);

  const handleClickOpen = () => {
    if (hasToken()) {
      if (!_.get(auth, "userData.user.phone_verified", false)) setOpen(true);
    } else {
      gotoLogIn();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span onClick={handleClickOpen}>{children}</span>
      <Dialog
        open={open}
        TransitionComponent={SlideTransition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {!_.get(auth, "userData.user.phone_verified", false) && (
              <Box>
                <Typography variant="body2">
                  We can't send you an SMS,{" "}
                  {_.get(auth, "userData.user.phone_number", false)
                    ? `You have not verified your phone number.`
                    : `You have not added your phone number.`}{" "}
                  <Link
                    component={RouterLink}
                    to={paths.setting}
                    variant="body2"
                  >
                    Click Here
                  </Link>{" "}
                  to go to setting.
                </Typography>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Colse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationPreferencesDialog;
