import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme, Typography, Button } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import _ from "lodash";
import currencyFormat from "utils/currencyFormat";
import { paths } from "routes";
import style from "./style";
import ProductImages from "./ProductImages";
import { getTimeDifference, getTimeDifferenceForFuture } from "utils";
import { differenceInDays } from "date-fns";

const getListingDetails = (listing) => {
  let typeList = "";
  if (listing.listingType === "Auction") typeList = "Bid";
  else if (listing.listingType === "AuctionWithBIN")
    typeList = "Bids or Buy It Now";
  else if (
    listing.listingType === "FixedPrice" &&
    listing.bestOfferEnabled === "true"
  )
    typeList = "Buy It Now or Best Offer";
  else if (listing.listingType === "FixedPrice") typeList = "Buy It Now";
  else typeList = listing.listingType;

  return typeList;
};

const StartTimer = (props) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    setTimeout(() => setSeconds(seconds + 1), 1000);
  });

  return (
    <Fragment key={seconds}>
      {getTimeDifference(_.get(props, "startTime"))} ago
    </Fragment>
  );
};

const EndTimer = (props) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    setTimeout(() => setSeconds(seconds + 1), 1000);
  });

  const days = differenceInDays(new Date(_.get(props, "endTime")), new Date());

  return (
    <Fragment key={seconds}>
      <span style={{ ...(days < 8 ? { color: "red" } : {}) }}>
        &nbsp;&nbsp;{getTimeDifferenceForFuture(_.get(props, "endTime"))}
      </span>
    </Fragment>
  );
};

const ProductListWithRightScroll = (props) => {
  const { newListData, searchKey = "", productId = -1 } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = style();

  const gotoProductPage = (itemId) => {
    let query = "";
    if (searchKey.length > 0) {
      query = "?ref=" + encodeURI(searchKey);
    }
    navigate(paths.productDetails.replace(":productId", itemId) + query, {
      state: { ref: "abc" },
    });
  };

  let itemWidth = 12;
  if (window.innerWidth < 1400) {
    itemWidth = 14;
  }
  if (window.innerWidth < 1000) {
    itemWidth = 18;
  }
  if (window.innerWidth < 800) {
    itemWidth = 22;
  }
  if (window.innerWidth < 500) {
    itemWidth = 34;
  }

  return (
    <>
      <Box style={{ overflowX: "scroll" }} mb={3}>
        <Box flex width={itemWidth * 20 + "vw"}>
          {_.map(
            newListData,
            (item, index) =>
              +productId !== +item.itemId && (
                <Box
                  className={classes.ItemGrid}
                  zeroMinWidth
                  style={{ width: itemWidth + "vw", display: "inline-block" }}
                  key={"newListData" + index}
                >
                  <Box className="wrapper" sx={{ p: "7px" }} mb={1}>
                    <Box
                      className="back-hover"
                      onClick={() => {
                        gotoProductPage(_.get(item, "itemId", "not-found"));
                      }}
                    >
                      <ProductImages
                        productDetails={item}
                        images={[item.galleryURL]}
                        loadInBack
                      />
                      <Box
                        display="flex"
                        style={{ maxWidth: "100%", minWidth: "0px" }}
                        onClick={() => {
                          gotoProductPage(_.get(item, "itemId", "not-found"));
                        }}
                      >
                        <Box
                          style={{
                            color: theme.palette.primary.main,
                            minWidth: "20px",
                          }}
                        >
                          <FiberManualRecordIcon fontSize="small" />
                        </Box>
                        <Typography
                          noWrap
                          variant="subtitle2"
                          className="item-title m-w-2x"
                          fontSize={13}
                        >
                          <StartTimer
                            startTime={_.get(item, "listingInfo.startTime")}
                          />
                          {" - " + item.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        noWrap
                        variant="subtitle2"
                        className="m-w-2x"
                        fontSize={12}
                      >
                        <b>
                          {"$" +
                            currencyFormat(
                              _.get(item, "sellingStatus.currentPrice.value", 0)
                            )}
                        </b>
                        &nbsp;
                        {getListingDetails(_.get(item, "listingInfo", ""))}
                        <b>
                          <EndTimer
                            endTime={_.get(item, "listingInfo.endTime")}
                          />
                        </b>
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() =>
                          gotoProductPage(_.get(item, "itemId", "not-found"))
                        }
                      >
                        <ArrowForwardIcon fontSize="small" />
                        <Typography
                          noWrap
                          variant="subtitle2"
                          className="m-w-2x"
                        >
                          &nbsp; See Details
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProductListWithRightScroll;
