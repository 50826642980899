import { useContext, useEffect } from "react";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Link,
  Typography,
} from "@material-ui/core";
import { loginService } from "services/authServices";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import { paths } from "routes";

const LoginCard = (props) => {
  const location = useLocation();
  const goto = new URLSearchParams(location.search).get("goto");
  const { auth, changeAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (_.get(auth, "isLoggedIn", false)) navigate(paths.login);
  }, [auth]);

  return (
    <Formik
      initialValues={{
        name: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required("Name is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (loginService) {
            const userData = await loginService({
              username: values.name,
              password: values.password,
            });
            if (userData) {
              changeAuth({ isLoggedIn: true, userData: userData.data });
              setStatus({ success: true });
              setSubmitting(false);
              if (goto) {
                window.location.href = goto;
              } else {
                navigate(paths.root);
              }
            }
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({
            submit: JSON.stringify(
              _.map(Object.keys(err.response.data), (key) =>
                _.map(err.response.data[key]).join(", ")
              ).join(", ")
            ),
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label="User Name"
            margin="normal"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
              {errors.submit === '"Email not verified"' && (
                <FormHelperText info>
                  <Link
                    color="textSecondary"
                    component={RouterLink}
                    sx={{ mt: 1, textDecoration: "underline" }}
                    to={paths.resendMailConfirm}
                    variant="body2"
                  >
                    Click Here
                  </Link>
                  <Typography
                    component={"span"}
                    color="textSecondary"
                    variant="body2"
                  >
                    &nbsp;to resend verification email
                  </Typography>
                </FormHelperText>
              )}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginCard;
