import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, useTheme, Typography, Button } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import _ from "lodash";
import currencyFormat from "utils/currencyFormat";
import { paths } from "routes";
import style from "./style";
import { getTimeDifference, getTimeDifferenceForFuture } from "utils";
import { differenceInDays } from "date-fns";
import ProductImages from "components/ProductList/ProductImages";

const StartTimer = (props) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    setTimeout(() => setSeconds(seconds + 1), 1000);
  });

  return (
    <Fragment key={seconds}>
      {getTimeDifference(_.get(props, "startTime"))} ago
    </Fragment>
  );
};

const EndTimer = (props) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    setTimeout(() => setSeconds(seconds + 1), 1000);
  });

  const days = differenceInDays(new Date(_.get(props, "endTime")), new Date());

  return (
    <Fragment key={seconds}>
      <span style={{ ...(days < 8 ? { color: "red" } : {}) }}>
        &nbsp;&nbsp;{getTimeDifferenceForFuture(_.get(props, "endTime"))}
      </span>
    </Fragment>
  );
};

const DigestProductList = (props) => {
  const { gridCol = 0, newListData, searchKey = "", productId = -1 } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = style();

  const gotoProductPage = (itemId) => {
    let query = "";
    if (searchKey.length > 0) {
      query = "?ref=" + encodeURI(searchKey);
    }
    navigate(paths.productDetails.replace(":productId", itemId) + query, {
      state: { ref: "abc" },
    });
  };

  return (
    <>
      <Grid container>
        {_.map(
          newListData,
          (item, index) =>
            +productId !== +item.item_id && (
              <Grid
                className={classes.ItemGrid}
                item
                md={gridCol === 0 ? 2 : gridCol}
                sm={gridCol === 0 ? 4 : gridCol}
                xs={gridCol === 0 ? 6 : gridCol}
                zeroMinWidth
                key={"newListData" + index}
              >
                <Box className="wrapper" sx={{ p: "7px" }} mb={3}>
                  <Box
                    className="back-hover"
                    onClick={() => {
                      gotoProductPage(_.get(item, "item_id", "not-found"));
                    }}
                  >
                    <ProductImages
                      productDetails={item}
                      images={[item.galleryURL]}
                      loadInBack={true}
                    />
                    <Box
                      display="flex"
                      style={{ maxWidth: "100%", minWidth: "0px" }}
                      onClick={() => {
                        gotoProductPage(_.get(item, "item_id", "not-found"));
                      }}
                    >
                      <Box
                        style={{
                          color: theme.palette.primary.main,
                          minWidth: "20px",
                        }}
                      >
                        <FiberManualRecordIcon fontSize="small" />
                      </Box>
                      <Typography
                        noWrap
                        variant="subtitle2"
                        className="item-title m-w-2x"
                        fontSize={13}
                      >
                        <StartTimer startTime={_.get(item, "pub_date")} />
                        {" - " + item.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      noWrap
                      variant="subtitle2"
                      className="m-w-2x"
                      fontSize={12}
                    >
                      <b>
                        {"$" + currencyFormat(_.get(item, "current_price", 0))}
                      </b>
                      &nbsp;
                      {_.get(item, "auction_type", "")}
                      <b>
                        <EndTimer endTime={_.get(item, "end_time")} />
                      </b>
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() =>
                        gotoProductPage(_.get(item, "item_id", "not-found"))
                      }
                    >
                      <ArrowForwardIcon fontSize="small" />
                      <Typography noWrap variant="subtitle2" className="m-w-2x">
                        &nbsp; See Details
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

export default DigestProductList;
