import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { SlideTransition } from "components/SlideTransition";
import PoweredbyStripe from "components/PoweredbyStripe";
import { addCard } from "services/authServices";
// import { poweredByStripe } from "assets";

const StripeCardChange = (props) => {
  const { children, isAddNew = false, addCallBack } = props;
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!loading) setOpen(false);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    if (isValid) {
      event.preventDefault();
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (error) console.log("[createPaymentMethod error]", error);
      else {
        // Change card API call here and pass paymentMethod.id
        console.log("[createPaymentMethod success]", paymentMethod);
        let res = await addCard(paymentMethod.id);
        if (res.status === 200) {
          toast.success("Card updated successfully");
          addCallBack();
        }
        handleClose();
      }
    }
    setLoading(false);
  };

  const handleChange = async (data) => {
    if (data.complete) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <>
      <span onClick={handleClickOpen}>{children}</span>
      <Dialog
        open={open}
        TransitionComponent={SlideTransition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>{isAddNew ? "Add" : "Change"} Card </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <>
              {open && (
                <CardElement
                  onChange={handleChange}
                  options={{
                    style: {
                      base: {
                        backgroundColor: "#fff",
                        fontSize: "16px",
                        lineHeight: "1.6",
                        letterSpacing: "-0.34px",
                        color: "#013648",
                      },
                    },
                  }}
                />
              )}
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box style={{ marginRight: "auto", marginLeft: "15px" }}>
            <PoweredbyStripe width={120} />
          </Box>
          <Button onClick={handleSubmit} color="primary">
            {loading ? <CircularProgress size={16} /> : "Save"}
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StripeCardChange;
