import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputAdornment,
  makeStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { paths } from "routes";
import { autoSuggest } from "services/ebayServices";
import _ from "lodash";

const style = makeStyles((theme) => ({
  searchInput: {
    "& input": {
      padding: "7px",
      paddingRight: "20px",
    },
    "& .MuiAutocomplete-clearIndicator": { paddingTop: "4px" },
    "& .MuiCircularProgress-indeterminate": { marginRight: "20px" },
  },
}));

let outerSearchKey = "";

const SearchBox = (props) => {
  const { defaultValue = "", stopRedirecting = false, changeCallback } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const classes = style();

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getData = async (searchKey) => {
    outerSearchKey = searchKey;
    if (changeCallback) {
      changeCallback(searchKey);
    }
    setLoading(true);
    const response = await autoSuggest({ keyword: searchKey });
    if (outerSearchKey === searchKey) {
      setOptions(_.get(response, "data.res.sug", []));
      setLoading(false);
    }
  };

  return (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      defaultValue={defaultValue}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        if (changeCallback) {
          changeCallback(value);
        }
        if (value && !stopRedirecting) {
          navigate(
            paths.search.replaceAll(
              ":searchKey",
              encodeURI(value).replaceAll("/", "%2F")
            )
          );
        }
      }}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => (option ? option : "")}
      options={options}
      loading={loading}
      freeSolo
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            className={classes.searchInput}
            onChange={(event) => {
              getData(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && e.target.value) {
                navigate(
                  paths.search.replace(
                    ":searchKey",
                    encodeURI(e.target.value).replaceAll("/", "%2F")
                  )
                );
              }
            }}
            placeholder="Search eBay and create instant alerts for new matches...."
            style={{ padding: "5px" }}
            InputProps={{
              ...params.InputProps,
              className: classes.searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default SearchBox;
