import { createContext, useEffect, useState } from "react";
import _ from "lodash";

const initialValue = {
  isLoggedIn: false,
  userData: {},
};

export const restoreContext = () => {
  let context = null;

  try {
    const storedData = window.localStorage.getItem("AuthContext");
    if (storedData) {
      context = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }
  return context;
};

export const storeValues = (values) => {
  if (_.get(values, "userData.token", false)) {
    window.localStorage.setItem("token", values.userData.token);
  } else {
    window.localStorage.removeItem("token");
  }
  window.localStorage.setItem("AuthContext", JSON.stringify(values));
};

const AuthContext = createContext({});

export const AuthProvider = (props) => {
  const { children } = props;
  const [auth, setAuth] = useState(initialValue);

  useEffect(() => {
    const restoredContext = restoreContext();

    if (restoredContext) {
      setAuth(restoredContext);
    }
  }, []);

  const changeAuth = (updatedAuth) => {
    storeValues({ ...auth, ...updatedAuth });
    setAuth({ ...auth, ...updatedAuth });
  };

  return (
    <AuthContext.Provider
      value={{
        auth: auth,
        changeAuth: changeAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
