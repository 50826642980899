import { useEffect, useRef, useState, Fragment } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  useTheme,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";

// Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import _ from "lodash";
import ShowMore from "react-show-more";
import { differenceInDays } from "date-fns";

import Scrollbar from "components/Scrollbar";
import { getItem } from "services/ebayServices";
import currencyFormat from "utils/currencyFormat";
import { getTimeDifferenceForFutureFullView } from "utils";

import ImageView from "./ImageView";
import PeopleAlsoLoved from "./PeopleAlsoLoved";
import { paths } from "routes";

const delay = async (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), time);
  });
};

const getListingDetails = (itemDetails) => {
  let typeList = "";
  if (_.get(itemDetails, "BestOfferEnabled", "false") === "false") {
    typeList = "Buy It Now or Best Offer";
  } else if (_.has(itemDetails, "MinimumToBid")) {
    typeList = "Auction";
  } else {
    typeList = "Buy It Now";
  }
  return typeList;
};

const EndTimer = (props) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    setTimeout(() => setSeconds(seconds + 1), 1000);
  });
  const days = differenceInDays(new Date(_.get(props, "endTime")), new Date());
  return (
    <Fragment key={seconds}>
      <span style={{ ...(days < 8 ? { color: "red" } : {}) }}>
        &nbsp;&nbsp;
        {getTimeDifferenceForFutureFullView(_.get(props, "endTime"))}
      </span>
    </Fragment>
  );
};

const ProductDetails = (props) => {
  const [itemDetails, setItemDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const refR = useRef(null);
  const theme = useTheme();
  const { productId } = useParams();
  const navigate = useNavigate();

  const search = useLocation().search;

  const getAndSetItemDetails = async () => {
    setLoading(true);
    let res = await getItem({
      item_id: productId,
    });
    if (
      _.get(res, "data.GetSingleItemResponse.Ack", "") === "Success" ||
      _.get(res, "data.GetSingleItemResponse.Ack", "") === "Warning"
    ) {
      setItemDetails(_.get(res, "data.GetSingleItemResponse.Item", {}));
    }
    setLoading(false);
  };

  useEffect(() => {
    getAndSetItemDetails();
  }, [productId]);

  // Image scrolling events
  const handleScrollLeft = async () => {
    let element = document.getElementById("product-image-scroll");
    if (!element) {
      const tempelement = document.getElementsByClassName(
        "scrollbar-container"
      );
      if (tempelement.length > 0) element = tempelement[0];
    }
    if (element)
      for (let i = 0; i < 75; i++) {
        await delay(5);
        element.scrollLeft -= 4;
      }
    else
      for (let i = 0; i < 75; i++) {
        await delay(5);
        refR.current.scrollLeft -= 4;
      }
  };
  const scrollRight = async () => {
    let element = document.getElementById("product-image-scroll");
    if (!element) {
      const tempelement = document.getElementsByClassName(
        "scrollbar-container"
      );
      if (tempelement.length > 0) element = tempelement[0];
    }
    if (element)
      for (let i = 0; i < 75; i++) {
        await delay(5);
        element.scrollLeft += 4;
      }
    else
      for (let i = 0; i < 75; i++) {
        await delay(5);
        refR.current.scrollLeft += 4;
      }
  };

  // Loader
  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  const quantity = _.get(itemDetails, "Quantity", 100);

  let ribbon = "";
  if (+quantity < 10) {
    ribbon = "Only 1 available";
  } else if (
    _.get(
      itemDetails,
      "ShippingCostSummary.ShippingServiceCost.value",
      "0.0"
    ) === "0.0"
  ) {
    ribbon = "FREE Shipping";
  }

  const handleSellerContact = () => {
    window.open(
      `https://contact.ebay.com/ws/eBayISAPI.dll?ShowSellerFAQ&iid=${productId}&requested=${_.get(
        itemDetails,
        "Seller.UserID",
        ""
      )}`
    );
  };

  const pictureURL = _.get(itemDetails, "PictureURL", []);
  const pictureArray = _.map(
    _.isArray(pictureURL) ? pictureURL : [pictureURL],
    (item, index) => ({
      index: index + 1,
      src: item,
      thumbnail: item,
      w: window.innerWidth,
      h: window.innerHeight,
      title: _.get(itemDetails, "Title", ""),
    })
  );

  const galleryView = (
    <Box position="relative" mt={2}>
      <Scrollbar ref={refR} id="product-image-scroll">
        <Box whiteSpace="nowrap">
          <ImageView images={pictureArray} />
        </Box>
      </Scrollbar>
      <Box
        onClick={handleScrollLeft}
        className="cursorPointer pos-abs"
        style={{
          top: 0,
          bottom: "5px",
          width: "50px",
          background:
            "linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0))",
        }}
      >
        <Box
          className="pos-abs color-white"
          style={{
            top: "calc(50% - 17px)",
            bottom: "5px",
            left: "19px",
          }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Box>
      </Box>
      <Box
        onClick={scrollRight}
        className="cursorPointer pos-abs"
        style={{
          top: 0,
          right: 0,
          bottom: "5px",
          width: "50px",
          background:
            "linear-gradient(90deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5))",
        }}
      >
        <Box
          className="pos-abs color-white"
          style={{
            top: "calc(50% - 17px)",
            bottom: "5px",
            right: "19px",
          }}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Box>
      </Box>
      {ribbon.length > 0 && (
        <span className="ribbon-danger">
          <span className="text">{ribbon}</span>
        </span>
      )}
    </Box>
  );

  let navigations = [
    <span className="cursorPointer" onClick={() => navigate(paths.root)}>
      Home
    </span>,
    " > ",
  ];

  const ref = new URLSearchParams(search).get("ref");
  if (ref) {
    navigations.push(
      <span
        className="cursorPointer"
        onClick={() => navigate(paths.search.replace(":searchKey", ref))}
      >
        Search "{ref}"
      </span>
    );
    navigations.push(" > ");
  }

  navigations.push(
    <span className="cursorPointer">{_.get(itemDetails, "Title", "")}</span>
  );

  return (
    <Box>
      <Box display="flex">
        <Typography variant="subtitle2">
          {_.map(navigations, (nav) => nav)}
        </Typography>
      </Box>
      {galleryView}
      <Box pt={2}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          style={{ width: "250px" }}
          onClick={() =>
            window.open(_.get(itemDetails, "ViewItemURLForNaturalSearch"))
          }
        >
          <ArrowForwardIcon fontSize="medium" />
          <Typography noWrap variant="h6">
            &nbsp;See Details on eBay
          </Typography>
        </Button>
      </Box>
      <Box pt={2}>
        <Typography variant="h5">{_.get(itemDetails, "Title", "")}</Typography>
        <Typography variant="subtitle1">
          {_.get(itemDetails, "Subtitle", "")}
        </Typography>

        <Box display="flex" className="mt-5">
          <Typography variant="h6">
            <span style={{ fontWeight: "700" }}>
              {"$" +
                currencyFormat(
                  _.get(itemDetails, "ConvertedCurrentPrice.value", 0)
                )}
              &nbsp;
            </span>
            <span style={{ color: "rgba(0,0,0,0.6)" }}>
              {getListingDetails(itemDetails)}&nbsp;
            </span>
            <span style={{ color: theme.palette.error.main }}>
              <EndTimer endTime={_.get(itemDetails, "EndTime")} />
            </span>
            <span>
              ,&nbsp;
              {_.get(
                itemDetails,
                "ShippingCostSummary.ShippingServiceCost.value",
                "0.0"
              ) === "0.0"
                ? "Free"
                : "$" +
                  currencyFormat(
                    _.get(
                      itemDetails,
                      "ShippingCostSummary.ShippingServiceCost.value",
                      "0.0"
                    )
                  )}
              &nbsp;Shipping&nbsp;&nbsp;
            </span>
            {_.get(itemDetails, "ReturnPolicy.Refund", "") === "Money Back" && (
              <span
                className="searchLink"
                style={{ color: theme.palette.primary.main }}
                onClick={() =>
                  window.open(
                    "https://pages.ebay.com/ebay-money-back-guarantee/"
                  )
                }
              >
                eBay Money Back Guarantee
              </span>
            )}
          </Typography>
        </Box>
        <Box display="flex" className="mt-5">
          <Typography variant="h6">
            <span style={{ fontWeight: "700" }}>Seller: </span>
            <span
              className="searchLink"
              style={{ color: theme.palette.primary.main }}
              onClick={() =>
                window.open(
                  "https://www.ebay.com/usr/" +
                    _.get(itemDetails, "Seller.UserID", "")
                )
              }
            >
              {_.get(itemDetails, "Seller.UserID", "")}&nbsp;
            </span>
            <Tooltip title="Contact Seller">
              <MailOutlineIcon
                fontSize="medium"
                onClick={handleSellerContact}
              />
            </Tooltip>
            <span>
              &nbsp;
              <Tooltip
                title={
                  currencyFormat(
                    _.get(itemDetails, "Seller.FeedbackScore", 0)
                  ) + " Positive Rating"
                }
              >
                <span>
                  (
                  {currencyFormat(
                    _.get(itemDetails, "Seller.FeedbackScore", 0)
                  )}
                  )
                </span>
              </Tooltip>
              &nbsp;
              <Tooltip
                title={
                  currencyFormat(
                    _.get(itemDetails, "Seller.PositiveFeedbackPercent", 0)
                  ) + "% Positive Feedback"
                }
              >
                <span>
                  {_.get(itemDetails, "Seller.PositiveFeedbackPercent", 0)}%
                </span>
              </Tooltip>
              , <b>Location:</b>
              &nbsp;
              {_.get(itemDetails, "Location", "")}, <b>Ship to:</b>{" "}
              {_.get(itemDetails, "ShipToLocations", "")},&nbsp;
              <b>Item:</b> {productId}
            </span>
          </Typography>
        </Box>
        <Box display="flex" mt={2} className="mt-5">
          <Typography variant="h6">
            <ShowMore
              anchorClass="txtDecorNone"
              lines={3}
              style={{ textDecoration: "none !important" }}
              more={
                <>
                  <Box display="inline-flex" style={{ textDecoration: "none" }}>
                    <ArrowDropDownIcon
                      fontSize="medium"
                      style={{ color: theme.palette.primary.main }}
                    />
                    <span
                      style={{
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                      }}
                    >
                      See More
                    </span>
                  </Box>
                </>
              }
              less={
                <Box display="inline-flex" style={{ TextDecoder: "none" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    style={{ color: theme.palette.primary.main }}
                  />
                  <span style={{ color: theme.palette.primary.main }}>
                    See Less
                  </span>
                </Box>
              }
            >
              <span
                className="searchLink"
                style={{ color: theme.palette.primary.main }}
              >
                {_.get(itemDetails, "Title", "")}
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: _.get(itemDetails, "Description", ""),
                }}
              ></div>
            </ShowMore>
          </Typography>
        </Box>
      </Box>
      <PeopleAlsoLoved
        {...props}
        productId={productId}
        title={_.get(itemDetails, "Title", "")}
        primaryCategoryID={itemDetails.PrimaryCategoryID}
      />
    </Box>
  );
};

export default withWidth()(ProductDetails);
