import { makeStyles } from "@material-ui/core";

let drawerWidth = 240;
const drawerCloseWidth = 45;

export default makeStyles((theme) => {
  drawerWidth = window.innerWidth < 550 ? window.innerWidth : drawerWidth;
  return {
    root: {
      display: "flex",
      "& .FormControl": {
        marginTop: "0px",
      },
      "& .FormControlLabel": {
        marginRight: "0px",
        height: "30px",
        "& .MuiFormControlLabel-label": {
          width: "100%",
        },
        "& .categories": {
          width: "140px",
          ["@media (max-width:550px)"]: {
            width: "100%",
            maxWidth: "calc(100vw - 100px)",
          },
        },
        "& .moreFilters": {
          width: "175px",
          ["@media (max-width:550px)"]: {
            width: "100%",
            maxWidth: "calc(100vw - 50px)",
          },
        },
      },
      "& .auto-searchLink": {
        display: "flex",
        color: theme.palette.primary.main,
        cursor: "pointer",
        padding: "2px 0px",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "& .filter-title-wrapper": {
        display: "flex",
        minWidth: "0px",
        "& .icon-class": {
          display: "flex",
          minWidth: "0px",
        },
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerClose: {
      width: drawerCloseWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      top: "64px !important",
      height: "calc(100vh - 64px) !important",
    },
    drawerPaperClose: {
      width: drawerCloseWidth,
      top: "64px !important",
    },
    drawerHeader: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    drawerCloseHeader: {
      display: "flex",
      alignItems: "center",
      padding: 0,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: -drawerCloseWidth,
    },
  };
});
