import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import config from "configs";
import React from "react";
import { CheckoutForm } from "./CheckoutForm";
const stripePromise = loadStripe(config.stripePublicKey);

const AccountSubscription = () => {
  return (
    <Card>
      <CardHeader title="Subscription" />
      <Divider />
      <CardContent>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </CardContent>
    </Card>
  );
};

export default AccountSubscription;
