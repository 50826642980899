import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import RegisterCard from "components/RegisterCard";
import { paths } from "routes";
import { logo } from "assets";

const Register = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ px: "0px", py: "80px" }}>
          <Box
            sx={{
              mb: 5,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <RouterLink to={paths.root} style={{ textDecoration: "none" }}>
              <Typography color="textPrimary" gutterBottom variant="h1">
                <img src={logo} width="300px" alt="logo" />
              </Typography>
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Register
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <RegisterCard />
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                to={paths.login}
                variant="body2"
              >
                Having an account
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Register;
