import { useState } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  FormGroup,
  useTheme,
  Drawer,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { IconFilter, IconArrowBigRight } from "@tabler/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "components/Accordion";
import _ from "lodash";
import clsx from "clsx";
import Scrollbar from "../Scrollbar";
import style from "./style";
import { itemConditions, itemListings } from "utils/constants";

const FilterMenu = (props) => {
  const {
    descriptionSearch,
    setDescriptionSearch,
    listingType,
    setListingType,
    conditionList,
    setConditionList,
    topRatedSellerOnly,
    setTopRatedSellerOnly,
    freeShippingOnly,
    setFreeShippingOnly,
    returnsAcceptedOnly,
    setReturnsAcceptedOnly,
    localPickupOnly,
    setLocalPickupOnly,
    endIn24Hr,
    setEndIn24Hr,
    startFrom7D,
    setStartFrom7D,
    authorizedSellerOnly,
    setAuthorizedSellerOnly,
    setMinPriceFilter,
    minPriceFilter,
    setMaxPriceFilter,
    maxPriceFilter,
    aspectList,
    activeAspectFilter,
    setActiveAspectFilter,
    fetchingSearchResult,
  } = props;
  let classes = style();
  const [open, setOpen] = useState(
    (localStorage.getItem("filterState") || "true") === "true"
  );
  const [minP, setMinP] = useState(minPriceFilter);
  const [maxP, setMaxP] = useState(maxPriceFilter);
  const [expanded, setExpanded] = useState(["categories"]);
  const theme = useTheme();

  const handleChange = (panel) => {
    if (expanded.indexOf(panel) > -1) {
      const newExpanded = _.remove(
        _.cloneDeep(expanded),
        (item) => item !== panel
      );
      setExpanded(newExpanded);
    } else setExpanded([..._.cloneDeep(expanded), panel]);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("filterState", "true");
  };
  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("filterState", "false");
  };

  const handleListingChange = (event) => {
    setListingType({
      ...listingType,
      [event.target.name]: event.target.checked,
    });
  };

  const handleConditionChange = (event) => {
    setConditionList({
      ...conditionList,
      [event.target.name]: event.target.checked,
    });
  };

  let numSelectedListing = 0;
  _.forEach(listingType, function (value, key) {
    if (value) numSelectedListing++;
  });

  let numSelectedMoreFilter =
    (topRatedSellerOnly ? 1 : 0) +
    (freeShippingOnly ? 1 : 0) +
    (returnsAcceptedOnly ? 1 : 0) +
    (localPickupOnly ? 1 : 0) +
    (endIn24Hr ? 1 : 0) +
    (startFrom7D ? 1 : 0) +
    (authorizedSellerOnly ? 1 : 0);

  let numSelectedCondition = 0;
  _.forEach(conditionList, function (value, key) {
    if (value) numSelectedCondition++;
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawerClose}
        variant="persistent"
        anchor="left"
        open={!open}
        classes={{
          paper: classes.drawerPaperClose,
        }}
      >
        <div className={classes.drawerCloseHeader}>
          <IconButton onClick={handleDrawerOpen}>
            <ArrowForwardIcon />
          </IconButton>
        </div>
      </Drawer>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Scrollbar>
          <Box className="filter-title-wrapper">
            <Box pl={1} className="icon-class">
              <Box pt={2} pr={1}>
                <IconFilter
                  fill="#172b4d"
                  width="32"
                  height="32"
                  lineHeight="64px"
                />
              </Box>
              <Typography variant="h6" lineHeight={"64px"}>
                Filter
              </Typography>
            </Box>
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <ArrowBackIcon />
              </IconButton>
            </div>
          </Box>
          <Divider />
          <Box p={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={descriptionSearch}
                  onChange={(event) => {
                    if (setDescriptionSearch)
                      setDescriptionSearch(event.target.checked);
                  }}
                  color="primary"
                />
              }
              label={<Typography variant="h6">Include Description</Typography>}
            />
          </Box>
          <Accordion
            square
            expanded={expanded.indexOf("listings") > -1}
            onChange={() => handleChange("listings")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography lineHeight="33px" variant="h6">
                Listings{" "}
                {numSelectedListing > 0 ? `(${numSelectedListing})` : ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl
                component="fieldset"
                className="FormControl"
                fullWidth
              >
                <FormGroup fullWidth>
                  {_.map(itemListings, (item, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={listingType[item.id]}
                          onChange={handleListingChange}
                          color="primary"
                          name={item.id}
                        />
                      }
                      key={"Listings" + index}
                      className={"FormControlLabel"}
                      label={
                        <Box fullWidth>
                          <Typography noWrap className="moreFilters">
                            {item.title}
                          </Typography>
                        </Box>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          {/* More Filters */}
          <Accordion
            square
            expanded={expanded.indexOf("moreFilters") > -1}
            onChange={() => handleChange("moreFilters")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography lineHeight="33px" variant="h6">
                More Filters{" "}
                {numSelectedMoreFilter > 0 ? `(${numSelectedMoreFilter})` : ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl
                component="fieldset"
                className="FormControl"
                fullWidth
              >
                <FormGroup fullWidth>
                  {/* Top-Rated Sellers */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={topRatedSellerOnly}
                        onChange={(event) =>
                          setTopRatedSellerOnly(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    className={"FormControlLabel"}
                    label={
                      <Box fullWidth>
                        <Typography noWrap className="moreFilters">
                          Top-Rated Sellers
                        </Typography>
                      </Box>
                    }
                  />
                  {/* Free Shipping */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={freeShippingOnly}
                        onChange={(event) =>
                          setFreeShippingOnly(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    className={"FormControlLabel"}
                    label={
                      <Box fullWidth>
                        <Typography noWrap className="moreFilters">
                          Free Shipping
                        </Typography>
                      </Box>
                    }
                  />
                  {/* Returns Accepted */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={returnsAcceptedOnly}
                        onChange={(event) =>
                          setReturnsAcceptedOnly(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    className={"FormControlLabel"}
                    label={
                      <Box fullWidth>
                        <Typography noWrap className="moreFilters">
                          Returns Accepted
                        </Typography>
                      </Box>
                    }
                  />
                  {/* Local Pickup */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={localPickupOnly}
                        onChange={(event) =>
                          setLocalPickupOnly(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    className={"FormControlLabel"}
                    label={
                      <Box fullWidth>
                        <Typography noWrap className="moreFilters">
                          Local Pickup
                        </Typography>
                      </Box>
                    }
                  />
                  {/* Listing ending within 24 hours */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={endIn24Hr}
                        onChange={(event) => setEndIn24Hr(event.target.checked)}
                        color="primary"
                      />
                    }
                    className={"FormControlLabel"}
                    label={
                      <Box fullWidth>
                        <Typography noWrap className="moreFilters">
                          Listing ending within 24 hours
                        </Typography>
                      </Box>
                    }
                  />
                  {/* Listings new within last 7 days */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={startFrom7D}
                        onChange={(event) =>
                          setStartFrom7D(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    className={"FormControlLabel"}
                    label={
                      <Box fullWidth>
                        <Typography noWrap className="moreFilters">
                          Listings new within last 7 days
                        </Typography>
                      </Box>
                    }
                  />
                  {/* Authorized Seller */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={authorizedSellerOnly}
                        onChange={(event) =>
                          setAuthorizedSellerOnly(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    className={"FormControlLabel"}
                    label={
                      <Box fullWidth>
                        <Typography noWrap className="moreFilters">
                          Authorized Seller
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          {/* Condition */}
          <Accordion
            square
            expanded={expanded.indexOf("condition") > -1}
            onChange={() => handleChange("condition")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography lineHeight="33px" variant="h6">
                Condition{" "}
                {numSelectedCondition > 0 ? `(${numSelectedCondition})` : ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl
                component="fieldset"
                className="FormControl"
                fullWidth
              >
                <FormGroup fullWidth>
                  {_.map(itemConditions, (item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={conditionList[item.id]}
                          onChange={handleConditionChange}
                          color="primary"
                          name={item.id}
                        />
                      }
                      className={"FormControlLabel"}
                      label={
                        <Box fullWidth>
                          <Typography noWrap className="moreFilters">
                            {item.title}
                          </Typography>
                        </Box>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          {/* Price */}
          <Accordion
            square
            expanded={expanded.indexOf("price") > -1}
            onChange={() => handleChange("price")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography lineHeight="33px" variant="h6">
                Price
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex">
                <TextField
                  placeholder="Min"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  size="small"
                  value={minP}
                  onChange={(event) => setMinP(event.target.value)}
                />
                <Box style={{ paddingLeft: "4px", paddingRight: "7px" }}>
                  <Typography lineHeight={"38px"} variant="h6">
                    -
                  </Typography>
                </Box>
                <TextField
                  placeholder="Max"
                  type="number"
                  InputLabelProps={{
                    shrink: false,
                  }}
                  variant="outlined"
                  size="small"
                  value={maxP}
                  onChange={(event) => setMaxP(event.target.value)}
                />
                <IconButton
                  onClick={() => {
                    setMinPriceFilter(minP);
                    setMaxPriceFilter(maxP);
                  }}
                  style={{
                    background: theme.palette.primary.main,
                    borderRadius: "5px",
                    padding: "5px",
                    margin: "5px",
                    marginLeft: "8px",
                    marginRight: "0px",
                  }}
                >
                  <IconArrowBigRight
                    color={theme.palette.primary.main}
                    width="18"
                    height="20"
                    fill={"white"}
                  />
                </IconButton>
              </Box>
            </AccordionDetails>
          </Accordion>
          {fetchingSearchResult ? (
            <Box textAlign="center" mt={3}>
              <CircularProgress></CircularProgress>
            </Box>
          ) : (
            <>
              {_.map(activeAspectFilter, (aspect) => (
                <Accordion
                  square
                  expanded={expanded.indexOf(aspect.aspectName) > -1}
                  onChange={() => handleChange(aspect.aspectName)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography lineHeight="33px" variant="h6">
                      {aspect.aspectName}{" "}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {expanded.indexOf(aspect.aspectName) > -1 && (
                      <FormControl
                        component="fieldset"
                        className="FormControl"
                        fullWidth
                      >
                        <FormGroup fullWidth>
                          {_.map(
                            _.get(aspect, "aspectValueName", []),
                            (item) => (
                              <FormControlLabel
                                key={item}
                                control={
                                  <Checkbox
                                    checked={true}
                                    onChange={(event) => {
                                      setActiveAspectFilter(
                                        _.filter(
                                          activeAspectFilter,
                                          (afilters) =>
                                            afilters.aspectName !==
                                              aspect.aspectName &&
                                            afilters.aspectValueName[0] !== item
                                        )
                                      );
                                    }}
                                    color="primary"
                                    name={item}
                                  />
                                }
                                className={"FormControlLabel"}
                                label={
                                  <Box fullWidth>
                                    <Typography noWrap className="moreFilters">
                                      {item}
                                    </Typography>
                                  </Box>
                                }
                              />
                            )
                          )}
                        </FormGroup>
                      </FormControl>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
              {_.map(aspectList, (aspect) => (
                <Accordion
                  square
                  expanded={expanded.indexOf(aspect._name) > -1}
                  onChange={() => handleChange(aspect._name)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography lineHeight="33px" variant="h6">
                      {aspect._name}{" "}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {expanded.indexOf(aspect._name) > -1 && (
                      <FormControl
                        component="fieldset"
                        className="FormControl"
                        fullWidth
                      >
                        <FormGroup fullWidth>
                          {_.map(
                            _.get(aspect, "valueHistogram", []),
                            (item) => (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Checkbox
                                    checked={false}
                                    onChange={(event) => {
                                      setActiveAspectFilter([
                                        ...activeAspectFilter,
                                        {
                                          aspectName: aspect._name,
                                          aspectValueName: [item._valueName],
                                        },
                                      ]);
                                    }}
                                    color="primary"
                                    name={item._valueName}
                                  />
                                }
                                className={"FormControlLabel"}
                                label={
                                  <Box fullWidth>
                                    <Typography noWrap className="moreFilters">
                                      {item._valueName}
                                    </Typography>
                                  </Box>
                                }
                              />
                            )
                          )}
                        </FormGroup>
                      </FormControl>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
          <Box m={5}></Box>
        </Scrollbar>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {props.children}
      </main>
    </div>
  );
};

export default FilterMenu;
