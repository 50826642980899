import Home from "pages/home";
import SearchResult from "pages/searchResult";
import Layout from "components/Layout";
import ProductDetails from "pages/productDetails";
import Login from "pages/login";
import Register from "pages/register";
import PasswordRecovery from "pages/passwordRecovery";
import MailActivation from "pages/mailActivation";
import Digest from "pages/digest";
import Instant from "pages/instant";
import ResendMailActivation from "pages/mailActivation/ResendMailActivation";
import PasswordReset from "pages/passwordReset";
import Setting from "pages/setting";
import Privacy from "pages/privacy";
import Pricing from "pages/Pricing";

export const paths = {
  root: "/",
  login: "/login",
  register: "/register",
  passwordRecovery: "/password-recovery",
  resetPassword: "/authentication/password-reset/:token",
  mailConfirm: "/mail/confirm/:uid/:token",
  resendMailConfirm: "/mail/resend-confirm",
  home: "/home",
  search: "/search/:searchKey",
  productDetails: "/product/:productId",
  notification: "/instant/:uuid",
  digest: "/digest/:uuid",
  notificationSort: "/i/:uuid",
  digestSort: "/d/:uuid",
  setting: "/setting",
  privacypolicy: "/privacypolicy",
  pricing: "/pricing",
};

export const commonRoute = [
  {
    path: "*",
    element: <Layout />,
    children: [
      {
        path: paths.digest,
        element: <Digest />,
      },
      {
        path: paths.digestSort,
        element: <Digest />,
      },
      {
        path: paths.notification,
        element: <Instant />,
      },
      {
        path: paths.notificationSort,
        element: <Instant />,
      },
      {
        path: paths.mailConfirm,
        element: <MailActivation />,
      },
      {
        path: paths.resendMailConfirm,
        element: <ResendMailActivation />,
      },
      {
        path: paths.privacypolicy,
        element: <Privacy />,
      },
      {
        path: paths.pricing,
        element: <Pricing />,
      },
    ],
  },
];

export const authRoute = [
  {
    path: "*",
    element: <Layout />,
    children: [
      {
        path: paths.home,
        element: <Home />,
      },
      {
        path: paths.search,
        element: <SearchResult />,
      },
      {
        path: paths.productDetails,
        element: <ProductDetails />,
      },
      {
        path: paths.setting,
        element: <Setting />,
      },
      {
        path: "*",
        element: <Home />,
      },
    ],
  },
];

export const nonAuthRoute = [
  {
    path: "*",
    element: <Layout />,
    children: [
      {
        path: paths.root,
        element: <Pricing />,
      },
      {
        path: paths.home,
        element: <Pricing />,
      },
      {
        path: paths.login,
        element: <Login />,
      },
      {
        path: paths.register,
        element: <Register />,
      },
      {
        path: paths.passwordRecovery,
        element: <PasswordRecovery />,
      },
      {
        path: paths.resetPassword,
        element: <PasswordReset />,
      },
      {
        path: "*",
        element: <Login />,
      },
    ],
  },
];
