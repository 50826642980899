import DigestTitle from "./DigestTitle";
import DigestProductList from "components/DigestProductList";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "components/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from "@material-ui/core";

const DigestItem = (props) => {
  const { data } = props;
  const { items } = data;
  return (
    <>
      <Box m={2}>
        <Accordion square style={{ border: "none", boxShadow: "none" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <DigestTitle data={data} />
          </AccordionSummary>
          <AccordionDetails>
            <DigestProductList newListData={items} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default DigestItem;
