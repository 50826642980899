import { useContext, useEffect, useState } from "react";
import { Box, Divider, Link, Typography } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import currencyFormat from "utils/currencyFormat";
import StripeCardChange from "components/Dialog/StripeCardChange";
import AuthContext from "contexts/authContext";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  addSubscribe,
  cancelSubscription,
  getCard,
  removeCard,
  userService,
} from "services/authServices";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import { toast } from "react-toastify";
import _ from "lodash";

export const CheckoutForm = (props) => {
  const { auth, changeAuth } = useContext(AuthContext);
  const [card, setCard] = useState({
    last4: "XXXX",
    exp_month: "00",
    exp_year: "0000",
  });

  const getAndSetUserData = async () => {
    let res = await userService();
    changeAuth({
      ...auth,
      userData: {
        ...auth.userData,
        user: { ...auth.userData.user, ..._.get(res, "data", {}) },
      },
    });
  };

  useEffect(async () => {
    if (auth.userData.user.stripe_payment_id) {
      let res = await getCard();
      setCard(_.get(res, "data.data[0].card", "XXXX"));
    }
  }, [auth.userData.user]);

  return (
    <Box m={2} maxWidth={"500px"}>
      <Typography variant="h6">
        Available credits:{" "}
        {currencyFormat(_.get(auth, "userData.user.credits", 0))}
      </Typography>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      <Typography variant="h6">Attached Card: </Typography>
      {auth.userData.user.stripe_payment_id ? (
        <>
          <Box ml={1}>
            <Typography variant="subtitle1">
              XXXX XXXX XXXX {card.last4} | {card.exp_month}/{card.exp_year}
            </Typography>
          </Box>
          <Box mt={1}>
            <ConfirmationDialog
              dialogTitle={`Are you sure want to remove your attached card?`}
              dialogText={""}
              ConfirmCallBack={async () => {
                if (!_.isNull(auth.userData.user.stripe_subscription_id)) {
                  toast.error(
                    "You can't remove your card while you have auto-recharge on, please disable auto-recharge first",
                    {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                } else {
                  await removeCard();
                  toast.success("Your card has been removed successfully");
                  getAndSetUserData();
                }
              }}
            >
              <Link className="cursorPointer" onClick={() => {}}>
                Remove Card
              </Link>
            </ConfirmationDialog>
          </Box>
        </>
      ) : (
        <Box mt={1}>
          <StripeCardChange
            {...props}
            isAddNew
            addCallBack={() => {
              getAndSetUserData();
            }}
          >
            <Link className="cursorPointer">Add your card now</Link>
          </StripeCardChange>
        </Box>
      )}
      <Box mt={2}>
        <FormGroup>
          <FormControlLabel
            control={
              <ConfirmationDialog
                dialogTitle={`You will be charged for any pending amount at this moment, please confirm`}
                dialogText={""}
                ConfirmCallBack={async () => {
                  if (!_.isNull(auth.userData.user.stripe_subscription_id)) {
                    await cancelSubscription();
                    toast.success("Auto recharge turn off successfully");
                    getAndSetUserData();
                  }
                }}
              >
                <Switch
                  disabled={_.isNull(auth.userData.user.stripe_payment_id)}
                  checked={!_.isNull(auth.userData.user.stripe_subscription_id)}
                  onChange={async (e, d) => {
                    if (d) {
                      await addSubscribe();
                      toast.success("Auto recharge turn on successfully");
                      getAndSetUserData();
                    } else {
                    }
                  }}
                />
              </ConfirmationDialog>
            }
            label="Auto-recharge"
          />
        </FormGroup>
        <small>
          You will be charged $5 for 500 credits when balance goes below 100
        </small>
      </Box>
    </Box>
  );
};
