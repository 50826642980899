export const itemConditions = [
  { id: 1000, title: "New" },
  { id: 1500, title: "New other (see details)" },
  { id: 1750, title: "New with defects" },
  { id: 2000, title: "Certified refurbished" },
  { id: 2500, title: "Seller refurbished" },
  { id: 2750, title: "Like New" },
  { id: 3000, title: "Used" },
  { id: 4000, title: "Very Good" },
  { id: 5000, title: "Good" },
  { id: 6000, title: "Acceptable" },
  { id: 7000, title: "For parts or not working" },
];

export const itemListings = [
  { id: "All", title: "All Listings" },
  { id: "Auction", title: "Auction" },
  {
    id: "AuctionWithBIN",
    title: "Auctions with Buy It Now",
  },
  { id: "Classified", title: "Classified" },
  { id: "FixedPrice", title: "Buy It Now" },
  { id: "StoreInventory", title: "Store Inventory" },
];
