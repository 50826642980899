import { useState } from "react";
import { Box, Container, Divider, Tab, Tabs } from "@material-ui/core";
import AccountNotificationsSettings from "./AccountNotificationsSettings";
import AccountSecuritySettings from "./AccountSecuritySettings";
import AccountSubscription from "./AccountSubscription";
import { useLocation } from "react-router";

const tabs = [
  { label: "Profile", value: "notifications" },
  { label: "Security", value: "security" },
  { label: "Subscription", value: "subscription" },
];

const Setting = () => {
  const location = useLocation();
  const activetab = new URLSearchParams(location.search).get("activetab");
  const [currentTab, setCurrentTab] = useState(
    activetab ? activetab : "notifications"
  );

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };
  return (
    <>
      <Box
        sx={{
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === "notifications" && <AccountNotificationsSettings />}
            {currentTab === "security" && <AccountSecuritySettings />}
            {currentTab === "subscription" && <AccountSubscription />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Setting;
