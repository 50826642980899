import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Box, CircularProgress } from "@material-ui/core";
import _ from "lodash";
import style from "./style";
import { getItem } from "services/ebayServices";

const ProductImages = (props) => {
  const [actualImages, setActualImages] = useState([]);
  const { images, productDetails = {}, loadInBack = false } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSet, setIsSet] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const sliderRef = useRef(null);
  const classes = style();

  const ids = _.get(
    productDetails,
    "itemId",
    _.get(productDetails, "item_id", "")
  );

  useEffect(() => {
    if (loadInBack) {
      getAndSetImages();
    }
  }, []);

  const getAndSetImages = async () => {
    if (!isSet) {
      setLoading(true);
      setIsSet(true);
      if (ids.length > 0) {
        const res = await getItem({
          item_id: ids,
        });
        const images = _.get(
          res,
          "data.GetSingleItemResponse.Item.PictureURL",
          []
        );
        setActualImages(_.isArray(images) ? images : [images]);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div
        className={classes.ProductImages}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Slider
          ref={sliderRef}
          arrows={false}
          infinite={false}
          beforeChange={(oldI, newI) => {
            setActiveIndex(newI);
          }}
        >
          {showPreview && (
            <div>
              <div className="square cursorPointer">
                <div className="content">
                  <img width="100%" src={images} alt="product-img" />
                </div>
              </div>
            </div>
          )}
          {_.map(isHovered ? actualImages : [actualImages[0]], (img, index) => (
            <div key={img + index}>
              <div className="square cursorPointer">
                <div className="content">
                  <img
                    width="100%"
                    src={img}
                    alt="product-img"
                    onLoad={(e) => {
                      setTimeout(() => {
                        setShowPreview(false);
                      }, 500);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="btn-wrapper">
          <Box pl={1} className="count">
            {isLoading ? (
              <CircularProgress
                thickness={7}
                size={"18px"}
                style={{ color: "white", marginTop: "3px" }}
              />
            ) : (
              <>
                {activeIndex + 1}/
                {(actualImages.length === 0 ? images : actualImages).length}
              </>
            )}
          </Box>
          <Box>
            <NavigateBeforeIcon
              className="nav-btns"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (activeIndex !== 0) sliderRef.current.slickPrev();
                else
                  sliderRef.current.slickGoTo(
                    (actualImages.length === 0 ? images : actualImages).length -
                      1
                  );
              }}
            />
            <NavigateNextIcon
              className="nav-btns"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (
                  activeIndex !==
                  (actualImages.length === 0 ? images : actualImages).length - 1
                )
                  sliderRef.current.slickNext();
                else sliderRef.current.slickGoTo(0);
              }}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default ProductImages;
