import { useContext, useState, useEffect } from "react";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  Fade,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import { editDigest, getDigestDetails } from "services/ebayServices";
import { toast } from "react-toastify";
import { validateEmail } from "utils";

const MySearch = (props) => {
  const { searchCount } = props;
  const { auth } = useContext(AuthContext);
  const userName = _.get(auth, "userData.user.username", "");
  const [loading, setLoading] = useState(false);
  const [mySearchEdit, setMySearchEdit] = useState(false);
  const [checkDigest, setCheckDigest] = useState(false);
  const [howOften, setHowOften] = useState("0 11 * * *");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const theme = useTheme();

  const getAndSetDigest = async () => {
    let res = await getDigestDetails();
    if (res && res.data) {
      setCheckDigest(_.get(res, "data.enabled", false));
      if (_.get(res, "data.delivery_time", false)) {
        setHowOften(_.get(res, "data.delivery_time", "0 11 * * *"));
      } else {
        setHowOften("0 11 * * *");
      }
      setEmail(_.get(res, "data.delivery_email", ""));
    }
  };

  useEffect(() => {
    getAndSetDigest();
  }, []);

  useEffect(() => {
    setEmailError("");
  }, [checkDigest]);

  const titleView = (
    <Fade in={true} timeout={700} key="titleView">
      <Box
        p={2}
        pl={0}
        mb={2}
        display="flex"
        style={{ borderBottom: `1px solid ${theme.palette.text.primary}` }}
      >
        <Typography variant="h4" color="textPrimary">
          My search ({searchCount})
        </Typography>
      </Box>
    </Fade>
  );

  const handleSaveClick = async () => {
    if (!loading) {
      setLoading(true);
      let payload = { username: userName };
      if (checkDigest) {
        if (validateEmail(email)) {
          setEmailError("");
        } else {
          setEmailError("Please enter valid email");
          setLoading(false);
          return;
        }
      }
      payload.enabled = checkDigest;
      payload.delivery_email = email;
      payload.delivery_time = howOften;
      let res = await editDigest(payload);
      if (res && res.data) {
        toast.info("Your preferences updated successfully");
      }
      setLoading(false);
    }
  };

  const settingView = (
    <Fade in={true} timeout={700} key="settingView">
      <Box
        p={2}
        mb={2}
        style={{ border: `1px solid ${theme.palette.text.primary}` }}
      >
        <Box
          display="flex"
          pb={2}
          style={{ borderBottom: `1px solid ${theme.palette.text.primary}` }}
        >
          <Typography variant="h4" color="textPrimary">
            My search ({searchCount})
          </Typography>
          <Box flexGrow={1} textAlign="right">
            <CloseIcon
              onClick={() => setMySearchEdit(false)}
              fontSize="large"
              className="cursorPointer"
            />
          </Box>
        </Box>
        <Box
          p={1}
          style={{ borderBottom: `1px solid ${theme.palette.text.primary}` }}
        >
          <FormControl component="fieldset" className="FormControl" fullWidth>
            <FormGroup fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkDigest}
                    onChange={(event) => setCheckDigest(event.target.checked)}
                    color="primary"
                    name={"digest"}
                  />
                }
                className={"FormControlLabel"}
                label={"Digest"}
              />
            </FormGroup>
          </FormControl>
          {checkDigest && (
            <>
              <Typography variant="body2">
                Receive all alerts in a single email
              </Typography>
              <Box display="flex" mb={1} style={{ paddingTop: "5px" }}>
                <Typography variant="body2" lineHeight="40px" width="80px">
                  How often
                </Typography>
                <Select
                  variant="outlined"
                  value={howOften}
                  size="small"
                  style={{ width: "250px" }}
                  onChange={(event) => {
                    setHowOften(event.target.value);
                  }}
                >
                  <MenuItem value={"0 11 * * *"}>At most once a day</MenuItem>
                  <MenuItem value={"0 11 * * */6"}>
                    At most once a week
                  </MenuItem>
                </Select>
              </Box>{" "}
              <Box display="flex" mb={1}>
                <Typography variant="body2" lineHeight="40px" width="80px">
                  Deliver to
                </Typography>
                <TextField
                  type="email"
                  placeholder="your@email.com"
                  style={{ width: "250px" }}
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={emailError.length > 0}
                  helperText={emailError}
                ></TextField>
              </Box>
            </>
          )}
        </Box>
        <Box p={1} pt={2}>
          <Button
            style={{
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
            onClick={handleSaveClick}
          >
            {loading && (
              <>
                <CircularProgress
                  size={16}
                  style={{ color: "white" }}
                  className="color-white"
                />
                &nbsp;&nbsp;
              </>
            )}
            Save
          </Button>
          <Box display="inline" pl={2} onClick={() => setMySearchEdit(false)}>
            <Button>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </Fade>
  );

  return <>{!mySearchEdit ? titleView : settingView}</>;
};

export default MySearch;
