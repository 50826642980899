import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  useTheme,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { paths } from "routes";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import _ from "lodash";
import { deleteSubscription, editSaveSearch } from "services/ebayServices";
import AuthContext from "contexts/authContext";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import { itemConditions, itemListings } from "utils/constants";

const SearchTitle = (props) => {
  const { data, stopRedirect, callBack, deleteCallback } = props;
  const { auth } = useContext(AuthContext);

  const [searchKey, setSearchKey] = useState(_.get(data, "tracker_name", ""));
  const [deleteInprogress, setDeleteInprogress] = useState(false);
  const [lastSavedState, setLastSavedState] = useState({});
  const [emailEnable, setEmailEnable] = useState(
    _.get(data, "enabled", false) &&
      (_.get(data, "delivery_mode", 0) === 0 ||
        _.get(data, "delivery_mode", 0) === 4)
  );
  const [smsEnable, setSmsEnable] = useState(
    _.get(data, "enabled", false) &&
      (_.get(data, "delivery_mode", 0) === 1 ||
        _.get(data, "delivery_mode", 0) === 4)
  );
  const [isNotification, setNotification] = useState(
    _.get(data, "enabled", false)
  );
  const [isEditView, setIsEditView] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setLastSavedState(data);
  }, [data]);

  useEffect(() => {
    setNotification(emailEnable || smsEnable);
  }, [emailEnable, smsEnable]);

  const resetLastState = () => {
    setSearchKey(_.get(lastSavedState, "tracker_name", ""));
    setNotification(_.get(lastSavedState, "enabled", false));
    setEmailEnable(
      _.get(data, "enabled", false) &&
        (_.get(data, "delivery_mode", 0) === 0 ||
          _.get(data, "delivery_mode", 0) === 4)
    );
    setSmsEnable(
      _.get(data, "enabled", false) &&
        (_.get(data, "delivery_mode", 0) === 1 ||
          _.get(data, "delivery_mode", 0) === 4)
    );
  };

  const handleSaveClick = async () => {
    if (!loading) {
      setLoading(true);
      let mode = 0;
      let state = 1;
      if (smsEnable && emailEnable) {
        mode = 4;
      } else if (smsEnable) {
        mode = 1;
      } else if (emailEnable) {
        mode = 0;
      } else {
        state = 0;
      }
      let payload = {
        subscription_id: _.get(data, "subscription_id", 0),
        active: state,
        mode: mode,
      };
      let res = await editSaveSearch(payload);
      setLastSavedState(_.get(res, "data", {}));
      if (res && res.data) {
        toast.info("Your preferences updated successfully");
      }
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleteInprogress(true);
    let res = await deleteSubscription({
      subscription_id: _.get(data, "subscription_id", ""),
    });
    if (_.get(res, "data.status", -1) === 200) {
      deleteCallback(data);
    }
    setDeleteInprogress(false);
  };

  const handleNavigate = () => {
    const queryString =
      `?filter=` + JSON.stringify(_.get(data, "search_query_mapping", {}));
    if (!stopRedirect)
      navigate(paths.search.replace(":searchKey", searchKey) + queryString);
    else callBack && callBack(data.title);
  };

  const btnDelete = (
    <ConfirmationDialog
      dialogTitle="Are you sure want to delete this search?"
      dialogText={"Search - " + _.get(data, "tracker_name", "")}
      ConfirmCallBack={handleDelete}
    >
      {deleteInprogress ? (
        <CircularProgress
          size={24}
          thickness={4.5}
          style={{ color: "black" }}
        />
      ) : (
        <DeleteIcon fontSize="medium" className="cursorPointer" />
      )}
    </ConfirmationDialog>
  );

  const resolveBoolean = (value) => {
    if (_.isBoolean(value)) {
      return value ? "Yes" : "No";
    }
    return value;
  };
  const resolveItemFilter = (item) => {
    if (item.name === "HideDuplicateItems") {
      return "";
    } else if (item.name === "FreeShippingOnly") {
      return <span>Free Shipping</span>;
    } else if (item.name === "TopRatedSellerOnly") {
      return <span>Top-Rated Sellers</span>;
    } else if (item.name === "LocalPickupOnly") {
      return <span>Local Pickup</span>;
    } else if (item.name === "EndTimeTo") {
      return <span>Listing ending within 24 hours</span>;
    } else if (item.name === "StartTimeFrom") {
      return <span>Listings new within last 7 days</span>;
    } else if (item.name === "AuthorizedSellerOnly") {
      return <span>Authorized Seller</span>;
    } else if (item.name === "MinPrice") {
      return <span>Min Price: {item.value}</span>;
    } else if (item.name === "MaxPrice") {
      return <span>Max Price: {item.value}</span>;
    } else if (item.name === "ReturnsAcceptedOnly") {
      return <span>Returns Accepted</span>;
    } else if (item.name === "Condition") {
      if (_.isArray(item.value)) {
        const filteredData = _.filter(
          itemConditions,
          (condition) => _.indexOf(item.value, "" + condition.id) > -1
        );
        return (
          <span>
            {item.name +
              ": " +
              _.map(filteredData, (data) => data.title).join(",")}
          </span>
        );
      } else {
        const filteredData = _.filter(
          itemConditions,
          (condition) => condition.id === item.value
        );
        return (
          <span>
            {item.name + ": " + _.get(filteredData, "[0].title", item.value)}
          </span>
        );
      }
    } else if (item.name === "ListingType") {
      if (_.isArray(item.value)) {
        const filteredData = _.filter(
          itemListings,
          (list) => _.indexOf(item.value, "" + list.id) > -1
        );
        return (
          <span>
            {item.name +
              ": " +
              _.map(filteredData, (data) => data.title).join(", ")}
          </span>
        );
      } else {
        const filteredData = _.filter(
          itemListings,
          (list) => list.id === item.value
        );
        return (
          <span>
            {" "}
            {item.name + ": " + _.get(filteredData, "[0].title", item.value)}
          </span>
        );
      }
    }
    return <span>{item.name + ": " + resolveBoolean(item.value)}</span>;
  };
  const resolveSortOrder = (sortOrder, buyerPostalCode) => {
    if (sortOrder === "BestMatch") {
      return "Sort Best Match";
    } else if (sortOrder === "StartTimeNewest") {
      return "Sort Newly Listed";
    } else if (sortOrder === "EndTimeSoonest") {
      return "Sort Ending Soon";
    } else if (sortOrder === "PricePlusShippingLowest") {
      return "Sort Price Lowest";
    } else if (sortOrder === "PricePlusShippingHighest") {
      return "Sort Price Highest";
    } else if (sortOrder === "DistanceNearest") {
      return "Sort Distance: nearest first - " + buyerPostalCode;
    } else if (sortOrder === "BidCountMost") {
      return "Sort Most Bids";
    } else if (sortOrder === "WatchCountDecreaseSort") {
      return "Sort Most Watched";
    }
  };

  const searchTitle = (
    <>
      <Box minWidth={24}>
        <SearchIcon style={{ marginTop: "3px" }} fontSize="medium" />
      </Box>
      <Typography variant="h6" noWrap color="textPrimary" className="tooltip">
        <span
          style={{ color: theme.palette.primary.main }}
          onClick={handleNavigate}
          className="searchLink"
        >
          {searchKey}
        </span>{" "}
        <div className="tooltip-container">
          <Box>
            <span>
              Category: {_.get(data, "search_data.categoryId", "All")}
            </span>
          </Box>
          <Box>
            {_.map(data.search_data.aspectFilter, (item) => (
              <span>{item.aspectName + ": " + item.aspectValueName}</span>
            ))}
          </Box>
          <Box flexWrap>
            {_.map(data.search_data.itemFilter, (item) =>
              resolveItemFilter(item)
            )}
          </Box>
          <Box>
            {resolveSortOrder(
              data.search_data.sortOrder,
              _.get(data, "search_data.buyerPostalCode", "")
            )}
            {data.search_data.descriptionSearch && ", Include Description"}
          </Box>
        </div>
        {/* - {data.type} TODO -Waiting for the backend to add newly selected item flag */}
      </Typography>
    </>
  );

  const nonEditView = (
    <Box p={1} pl={0} display="flex">
      {searchTitle}
      <Box flexGrow={1} textAlign="right" minWidth={90}>
        <Box display="inline" style={{ color: theme.palette.error.main }}>
          {isNotification ? (
            <NotificationsIcon
              fontSize="medium"
              className="cursorPointer"
              onClick={() => {
                setNotification(false);
                setIsEditView(true);
              }}
            />
          ) : (
            <NotificationsOffIcon
              fontSize="medium"
              className="cursorPointer"
              onClick={() => {
                setNotification(true);
                setIsEditView(true);
              }}
            />
          )}
        </Box>
        &nbsp;
        {btnDelete}
        &nbsp;
        <EditIcon
          fontSize="medium"
          className="cursorPointer"
          onClick={() => setIsEditView(true)}
        />
      </Box>
    </Box>
  );

  const editView = (
    <>
      <Box
        p={2}
        mb={2}
        style={{ border: `1px solid ${theme.palette.text.primary}` }}
      >
        <Box p={1} pl={0} display="flex">
          {searchTitle}
          <Box flexGrow={1} textAlign="right" minWidth={90}>
            <Box display="inline" style={{ color: theme.palette.error.main }}>
              {isNotification ? (
                <NotificationsIcon
                  fontSize="medium"
                  className="cursorPointer"
                  onClick={() => {
                    setNotification(false);
                    setIsEditView(true);
                  }}
                />
              ) : (
                <NotificationsOffIcon
                  fontSize="medium"
                  className="cursorPointer"
                  onClick={() => {
                    setNotification(true);
                    setIsEditView(true);
                  }}
                />
              )}
            </Box>
            &nbsp;
            {btnDelete}
            &nbsp;
            <HighlightOffIcon
              fontSize="medium"
              className="cursorPointer"
              onClick={() => {
                setIsEditView(false);
                resetLastState();
              }}
            />
          </Box>
        </Box>
        <Box p={1}>
          <Box display="flex" mb={1}>
            <Typography variant="body2" lineHeight="40px" width="80px">
              Deliver to
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={emailEnable}
                  onChange={(event) => setEmailEnable(event.target.checked)}
                />
              }
              label="Email"
            />
            <FormControlLabel
              disabled={!_.get(auth, "userData.user.phone_verified", false)}
              control={
                <Checkbox
                  color="primary"
                  checked={smsEnable}
                  onChange={(event) => setSmsEnable(event.target.checked)}
                />
              }
              label="SMS"
            />
          </Box>
          {!_.get(auth, "userData.user.phone_verified", false) && (
            <Box>
              <Typography variant="body2">
                We can't send you an SMS,{" "}
                {_.get(auth, "userData.user.phone_number", false)
                  ? `You have not verified your phone number.`
                  : `You have not added your phone number.`}{" "}
                <Link component={RouterLink} to={paths.setting} variant="body2">
                  Click Here
                </Link>{" "}
                to go to setting.
              </Typography>
            </Box>
          )}
        </Box>
        <Box p={1}>
          <Button
            style={{
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              minWidth: "125px",
            }}
            onClick={handleSaveClick}
          >
            {loading && (
              <>
                <CircularProgress size={16} style={{ color: "white" }} />
                &nbsp;&nbsp;
              </>
            )}
            Update search
          </Button>
          <Box
            display="inline"
            pl={2}
            onClick={() => {
              setIsEditView(false);
              resetLastState();
            }}
          >
            <Button>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </>
  );

  return isEditView ? editView : nonEditView;
};

export default SearchTitle;
