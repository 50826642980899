import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Slider,
  withStyles,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

// Icons
import { IconLayoutGrid } from "@tabler/icons";
import AppsIcon from "@material-ui/icons/Apps";

import _ from "lodash";

import ProductList from "components/ProductList";
import { searchService } from "services/ebayServices";

const GridSlider = withStyles({
  root: {
    color: "#52af77",
    marginTop: "2px",
  },
  thumb: {
    minWidth: 15,
    minHeight: 15,
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    borderRadius: 4,
    minHeight: 5,
  },
  rail: {
    borderRadius: 4,
    minHeight: 5,
  },
})(Slider);

let outerPeoplePayload = {};

const PeopleAlsoLoved = (props) => {
  const { title, primaryCategoryID, productId } = props;
  const [searchResult, setSearchResult] = useState([]);
  const [searchResultItemList, setSearchResultItemList] = useState([]);
  const [gridCol, setGridCol] = useState(2);
  const [fetchingSearchResult, setFetchingSearchResult] = useState(true);

  const [gridSlider, setGridSlider] = useState({
    values: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
    ],
    getValue: [1, 2, 3, 4, 6, 12],
    min: 1,
    max: 12,
  });

  useEffect(() => {
    if (isWidthUp("md", props.width)) {
      setGridSlider({
        values: [
          { value: 1 },
          { value: 2 },
          { value: 3 },
          { value: 4 },
          { value: 5 },
          { value: 6 },
        ],
        getValue: [1, 2, 3, 4, 6, 12],
      });
    } else if (isWidthUp("sm", props.width)) {
      setGridSlider({
        values: [{ value: 1 }, { value: 2 }, { value: 3 }],
        getValue: [4, 6, 12],
      });
      if (gridCol < 4) setGridCol(4);
    } else if (isWidthUp("xs", props.width)) {
      setGridSlider({
        values: [{ value: 1 }, { value: 2 }],
        getValue: [6, 12],
      });
      if (gridCol < 4) setGridCol(4);
    }
  }, [props.width]);

  // API call for search
  const getAndSetSearchResult = async (init = false) => {
    setFetchingSearchResult(true);
    let searchKey = "";
    const wordArr = title.split(" ");
    if (wordArr.length > 3) {
      searchKey = wordArr.splice(0, 3).join(" ");
    } else {
      searchKey = title;
    }

    let payload = {
      keywords: searchKey,
      paginationInput: {
        entriesPerPage: 20,
        pageNumber: init
          ? 1
          : +_.get(searchResult, "paginationOutput.pageNumber", 0) + 1,
      },
      itemFilter: [
        {
          name: "HideDuplicateItems",
          value: true,
        },
      ],
    };

    if (primaryCategoryID)
      payload = { ...payload, categoryId: primaryCategoryID };

    outerPeoplePayload = {};

    outerPeoplePayload = payload;

    const res = await searchService(payload);
    if (res.data && outerPeoplePayload === payload) {
      setSearchResult(_.get(res, "data", {}));
      if (init) {
        setSearchResultItemList([..._.get(res, "data.searchResult.item", [])]);
      } else {
        setSearchResultItemList([
          ...searchResultItemList,
          ..._.get(res, "data.searchResult.item", []),
        ]);
      }
    }
    setFetchingSearchResult(false);
  };

  useEffect(() => {
    setSearchResultItemList([]);
    setFetchingSearchResult(true);
    getAndSetSearchResult(true);
  }, [title]);

  const BtnSeeMoreView = !fetchingSearchResult &&
    _.get(searchResult, "paginationOutput.pageNumber", "0") !==
      _.get(searchResult, "paginationOutput.totalPages", "0") && (
      <Box textAlign="center" mb={3}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => getAndSetSearchResult(false)}
        >
          <Typography noWrap variant="subtitle2" className="m-w-2x">
            See More...
          </Typography>
        </Button>
      </Box>
    );

  const loader = fetchingSearchResult && (
    <Box mb={5} mt={searchResultItemList.length > 0 ? 0 : 5} textAlign="center">
      <CircularProgress />
    </Box>
  );

  return (
    <>
      <Box mt={2} display="flex">
        <Box>
          <Typography variant="h5">People Also Loved</Typography>
        </Box>
        <Box
          flexGrow={1}
          display="inline-flex"
          style={{
            marginBottom: "0px",
          }}
        >
          <Grid
            container
            spacing={1}
            style={{ width: "200px", height: "28px", marginLeft: "auto" }}
          >
            <Grid item>
              <AppsIcon fontSize="large" />
            </Grid>
            <Grid item xs>
              <GridSlider
                value={gridSlider.getValue.indexOf(gridCol) + 1}
                min={1}
                max={gridSlider.values.length}
                aria-labelledby="discrete-slider-restrict"
                step={null}
                valueLabelDisplay="off"
                marks={gridSlider.values}
                onChange={(event, value) => {
                  setGridCol(gridSlider.getValue[value - 1]);
                }}
              />
            </Grid>
            <Grid item>
              <Box sx={{ ml: "5px" }}>
                <IconLayoutGrid fill="#172b4d" width="35" height="35" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ProductList
        searchResult={searchResult}
        newListData={searchResultItemList}
        gridCol={gridCol}
        productId={productId}
      />
      {loader}
      {BtnSeeMoreView}
    </>
  );
};

export default withWidth()(PeopleAlsoLoved);
