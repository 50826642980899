import { useEffect, useRef, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { Box } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import Scrollbar from "../Scrollbar";
import Sidebar from "./Sidebar";

const Root = experimentalStyled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
  boxShadow: "none",
  ...(theme.palette.mode === "dark" && {
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const Layout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(true);
  const scrollRef = useRef();

  const location = useLocation();

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [location.pathname]);

  return (
    <DashboardLayoutRoot>
      <Navbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)}
        isSidebarMobileOpen={isSidebarMobileOpen}
      />
      <Sidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Scrollbar ref={scrollRef}>
              <Root>
                <Outlet />
              </Root>
            </Scrollbar>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default Layout;
