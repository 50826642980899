import { useState, createContext } from "react";
import _ from "lodash";

export const RootContext = createContext({});

const RootContextWrapper = (props) => {
  const [state, setState] = useState({});

  const setGlobal = (index, newData) => {
    const { oldData } = index;
    if (!_.isEqual(oldData, newData)) {
      setState({
        ...state,
        [index]: newData,
      });
    }
  };

  const setGlobalState = (callback) => {
    setState(callback);
  };

  const { children } = props;
  return (
    <RootContext.Provider
      value={{
        ...state,
        setGlobal: setGlobal,
        setGlobalState: setGlobalState,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export default RootContextWrapper;
