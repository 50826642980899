import axiosInstance from "lib/axios";
import Configs from "configs";
import { gotoLogIn, hasToken } from "utils";

const searchService = async (data) => {
  return await axiosInstance.post(Configs.endpoints.ebay.search, data);
};

const autoSuggest = async (data) => {
  return await axiosInstance.post(Configs.endpoints.ebay.autoSuggest, data);
};

const saveSearch = async (data) => {
  if (hasToken()) {
    return await axiosInstance.post(Configs.endpoints.ebay.saveSearch, data);
  } else {
    gotoLogIn();
  }
};

const editSaveSearch = async (data) => {
  if (hasToken()) {
    return await axiosInstance.post(Configs.endpoints.ebay.editSearch, data);
  } else {
    gotoLogIn();
  }
};

const notificationSchedule = async (data) => {
  if (hasToken()) {
    return await axiosInstance.post(
      Configs.endpoints.ebay.notificationSchedule,
      data
    );
  } else {
    gotoLogIn();
  }
};

const editDigest = async (data) => {
  if (hasToken()) {
    return await axiosInstance.put(Configs.endpoints.ebay.editDigest, data);
  } else {
    gotoLogIn();
  }
};

const getAllSubscription = async (data) => {
  if (hasToken()) {
    return await axiosInstance.post(
      Configs.endpoints.ebay.getAllSubscription,
      data
    );
  }
};

const deleteSubscription = async (data) => {
  if (hasToken()) {
    return await axiosInstance.post(
      Configs.endpoints.ebay.deleteSubscription,
      data
    );
  } else {
    gotoLogIn();
  }
};

const getAllCategories = async (data) => {
  return await axiosInstance.post(
    Configs.endpoints.ebay.getAllCategories,
    data
  );
};

const getItem = async (data) => {
  return await axiosInstance.post(Configs.endpoints.ebay.getItem, data);
};

const getDigestDetails = async (data) => {
  if (hasToken()) {
    return await axiosInstance.get(Configs.endpoints.ebay.getDigest, data);
  }
};

const getDigestNotification = async (data) => {
  return await axiosInstance.post(
    Configs.endpoints.ebay.getDigestNotification,
    data
  );
};

const getNotification = async (data) => {
  return await axiosInstance.post(
    Configs.endpoints.ebay.getItemNotification,
    data
  );
};

export {
  searchService,
  saveSearch,
  autoSuggest,
  notificationSchedule,
  getAllSubscription,
  getAllCategories,
  deleteSubscription,
  getItem,
  getDigestDetails,
  editSaveSearch,
  editDigest,
  getDigestNotification,
  getNotification,
};
