import { useEffect, useState, Fragment } from "react";
import { Box, Divider, CircularProgress, Typography } from "@material-ui/core";
import { getDigestNotification } from "services/ebayServices";
import _ from "lodash";
import { useParams } from "react-router";
import DigestItem from "components/DigestItem";

const Digest = () => {
  const [loading, setLoading] = useState(true);
  const [allSearch, setAllSearch] = useState([]);
  const { uuid } = useParams();

  const getAndSetAllSearch = async () => {
    let res = await getDigestNotification({
      digest_uuid: uuid,
    }).catch((e) => {});
    if (res && res.data) {
      setAllSearch(_.get(res, "data.data", []));
    }
  };

  useEffect(async () => {
    setLoading(true);
    await getAndSetAllSearch();
    setLoading(false);
  }, []);

  return (
    <>
      {loading && (
        <Box m={5} textAlign="center">
          <CircularProgress />
          <Box>Hang on tight we are loading...</Box>
        </Box>
      )}
      {!loading &&
        _.map(allSearch, (item) => (
          <Fragment key={allSearch.tracker}>
            <Box>
              <DigestItem data={item} />
            </Box>
            <Divider />
          </Fragment>
        ))}
      {!loading && allSearch.length === 0 && (
        <Box m={3}>
          <Typography variant="h6">
            You don't have any new notifications{" "}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Digest;
