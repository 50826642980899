import { useContext } from "react";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  ListItemIcon,
  MenuItem,
  Toolbar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import CogIcon from "icons/Cog";
import { experimentalStyled } from "@material-ui/core/styles";
import AccountPopover from "components/Popovers/AccountPopover";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SearchBox from "components/SearchBox";
import AuthContext from "contexts/authContext";
import UserIcon from "icons/User";

import _ from "lodash";
import { paths } from "routes";
import { logo } from "assets";

const NavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  color: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 100,
  borderBottom: "1px solid rgb(229, 232, 235)",
}));

const Navbar = (props) => {
  const { onSidebarMobileOpen, isSidebarMobileOpen, ...other } = props;
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  let seachBoxPadding = _.get(auth, "isLoggedIn", false)
    ? "0px 20%"
    : "0px 10%";
  let seachBoxPaddingRight = _.get(auth, "isLoggedIn", false)
    ? "calc(10% + 175px)"
    : "calc(10% + 175px)";
  if (window.innerWidth < 960) {
    seachBoxPadding = "0px";
    seachBoxPaddingRight = "0px";
  } else if (window.innerWidth < 1100) {
    seachBoxPadding = "0px 15%";
    seachBoxPaddingRight = "calc(10% + 105px)";
  } else if (window.innerWidth < 1300) {
    seachBoxPadding = "0px 15%";
    seachBoxPaddingRight = "calc(10% + 105px)";
  }

  return (
    <>
      <NavbarRoot
        {...other}
        style={{
          boxShadow: isSidebarMobileOpen
            ? "none"
            : "rgb(14 14 14 / 25%) 0px 0px 8px 0px",
        }}
      >
        <Toolbar sx={{ minHeight: 64 }}>
          <Box
            className="cursorPointer"
            display={"inline-table"}
            onClick={() => navigate(paths.home)}
          >
            <img src={logo} alt="logo" style={{ width: "125px" }} />
          </Box>
          <Hidden mdUp>
            <Box pr={2} sx={{ marginLeft: "auto" }} display="inline">
              <Box>
                <IconButton color="inherit" onClick={onSidebarMobileOpen}>
                  {isSidebarMobileOpen ? (
                    <ExpandLessIcon fontSize="medium" />
                  ) : (
                    <ExpandMoreIcon fontSize="medium" />
                  )}
                </IconButton>
              </Box>
            </Box>
          </Hidden>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              width: "100%",
              padding: seachBoxPadding,
              paddingLeft: seachBoxPaddingRight,
            }}
          >
            <SearchBox />
          </Box>
          {_.get(auth, "isLoggedIn", false) && (
            <>
              <Hidden mdDown>
                <Box
                  sx={{
                    flexGrow: 1,
                    ml: 2,
                  }}
                />
                <Box sx={{ ml: 2 }}>
                  <Box display="flex" alignItems="center">
                    <AccountPopover />
                  </Box>
                </Box>
              </Hidden>
            </>
          )}
          {!_.get(auth, "isLoggedIn", false) && (
            <Hidden mdDown>
              <Box
                sx={{
                  flexGrow: 1,
                  ml: 2,
                }}
              />
              <Box display="flex" sx={{ ml: 2 }}>
                <MenuItem
                  component={RouterLink}
                  to={paths.login}
                  style={{ width: "230px" }}
                >
                  <ListItemIcon>
                    <UserIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        Login
                      </Typography>
                    }
                  />
                </MenuItem>
                <MenuItem
                  component={RouterLink}
                  to={paths.register}
                  style={{ width: "280px" }}
                >
                  <ListItemIcon>
                    <CogIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        Register
                      </Typography>
                    }
                  />
                </MenuItem>
              </Box>
            </Hidden>
          )}
        </Toolbar>
      </NavbarRoot>
    </>
  );
};

export default Navbar;
