import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";

export const getTimeDifference = (time) => {
  const days = differenceInDays(new Date(), new Date(time));
  if (days > 0) {
    return days + "d";
  }

  const hours = differenceInHours(new Date(), new Date(time));
  if (hours > 0) {
    return hours + "h";
  }

  const minutes = differenceInMinutes(new Date(), new Date(time));
  if (minutes > 0) {
    return minutes + "m";
  }

  const seconds = differenceInSeconds(new Date(), new Date(time));
  if (seconds > 0) {
    return seconds + "s";
  }
  return "";
};

export const getTimeDifferenceForFuture = (time) => {
  const days = differenceInDays(new Date(time), new Date());
  const hours = differenceInHours(new Date(time), new Date());
  const minutes = differenceInMinutes(new Date(time), new Date());
  const seconds = differenceInSeconds(new Date(time), new Date());

  if (days > 0) {
    return `${days}d ${hours - days * 24}h`;
  }

  if (hours > 0) {
    return `${hours}h ${minutes - hours * 60}m`;
  }

  if (minutes > 0) {
    return `${minutes}m ${seconds - minutes * 60}m`;
  }

  if (seconds > 0) {
    return seconds + "s";
  }
  return "";
};

export const getTimeDifferenceForFutureFullView = (time) => {
  const days = differenceInDays(new Date(time), new Date());
  const hours = differenceInHours(new Date(time), new Date());
  const minutes = differenceInMinutes(new Date(time), new Date());
  const seconds = differenceInSeconds(new Date(time), new Date());

  if (days > 0) {
    return `${days}d ${hours - days * 24}h ${minutes - hours * 60}m ${
      seconds - minutes * 60
    }s`;
  }

  if (hours > 0) {
    return `${hours}h ${minutes - hours * 60}m ${seconds - minutes * 60}s`;
  }

  if (minutes > 0) {
    return `${minutes}m ${seconds - minutes * 60}s`;
  }

  if (seconds > 0) {
    return seconds + "s";
  }
  return "";
};

export const hasToken = () => {
  return localStorage.getItem("token") ? true : false;
};

export const gotoLogIn = () => {
  return (window.location.href =
    window.location.protocol +
    "//" +
    window.location.host +
    "/login?goto=" +
    window.location.href);
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhoneNumber = (number) => {
  var phoneno = /^\+([0-9]{1,3})\)?([0-9]{10,12})$/;
  return phoneno.test(number);
};
