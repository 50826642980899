import { useState, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { resetPasswordService } from "services/authServices";
import AuthContext from "contexts/authContext";
import "react-phone-number-input/style.css";
import _ from "lodash";

const AccountSecuritySettings = () => {
  const [loading, setLoading] = useState(false);
  const { auth } = useContext(AuthContext);
  const sendLink = async () => {
    if (!loading) {
      setLoading(true);
      const userMail = _.get(auth, "userData.user.email", "");
      let res = await resetPasswordService({
        email: userMail,
      }).catch((err) => console.error(err));
      if (_.get(res, "data.status", "") === "OK") {
        toast.success("Reset password link sent on " + userMail);
      } else {
        toast.error(
          "Something went wrong please refresh the page and try again"
        );
      }
      setLoading(false);
    }
  };
  return (
    <Card>
      <CardHeader title="Change Password" />
      <Divider />
      <CardContent>
        <Button color="primary" variant="contained" onClick={sendLink}>
          {loading && (
            <>
              <CircularProgress size={16} style={{ color: "white" }} />
              &nbsp;{" "}
            </>
          )}
          Send change password link
        </Button>
      </CardContent>
    </Card>
  );
};

export default AccountSecuritySettings;
