import { useState, useEffect, useContext, Fragment } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ProductList from "components/ProductList";
import {
  Box,
  Button,
  useTheme,
  Grid,
  withStyles,
  MenuItem,
  Divider,
  Menu,
  Slider,
  Typography,
  makeStyles,
  TextField,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

// Icons
import { IconLayoutGrid, IconArrowBigRight } from "@tabler/icons";
import TextsmsIcon from "@material-ui/icons/Textsms";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import EmailIcon from "@material-ui/icons/Email";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import SortIcon from "@material-ui/icons/Sort";
import AppsIcon from "@material-ui/icons/Apps";
import CloseIcon from "@material-ui/icons/Close";

import _ from "lodash";
import { addDays, addHours } from "date-fns";
import { toast } from "react-toastify";

import AuthContext from "contexts/authContext";
import { paths } from "routes";
import {
  deleteSubscription,
  editSaveSearch,
  getAllSubscription,
  saveSearch,
  searchService,
} from "services/ebayServices";
import FilterMenu from "components/Filters/FilterMenu";

import CategoriesList from "./CategoriesList";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import { isJson } from "utils";
import NotificationPreferencesDialog from "components/Dialog/NotificationPreferencesDialog";

const style = makeStyles({
  customWidth: {
    "& .MuiPaper-rounded": {
      borderRadius: "0px",
    },
    "& .MuiListItem-root": {
      width: "185px",
    },
  },
});

const GridSlider = withStyles({
  root: {
    color: "#52af77",
    marginTop: "2px",
  },
  thumb: {
    minWidth: 15,
    minHeight: 15,
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    borderRadius: 4,
    minHeight: 5,
  },
  rail: {
    borderRadius: 4,
    minHeight: 5,
  },
})(Slider);

// Defualt Values for State
const girdDefualtValue = {
  values: [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
  ],
  getValue: [1, 2, 3, 4, 6, 12],
  min: 1,
  max: 12,
};
const sortingDefualValue = {
  display: "Newly Listed",
  value: "StartTimeNewest",
};

let outerPayload = {};

const SearchResult = (props) => {
  const params = new URLSearchParams(window.location.search); // id=123
  const filter = params.get("filter");
  const filterJson = isJson(filter) ? JSON.parse(filter) : {};

  const [isSmsTrue, setIsSmsTrue] = useState(false);
  const [isEmailTrue, setIsEmailTrue] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [allSaveSearch, setAllSaveSearch] = useState([]);
  const [saveSearchLoading, setSaveSearchLoading] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState(-1);

  // States for Categorys
  const [categoryFilterList, setCategoryFilterList] = useState([]);
  const [activeCategoryId, setActiveCategoryId] = useState(
    _.get(filterJson, "activeCategoryId", -1)
  );

  // States for Categorys
  const [aspectList, setAspectList] = useState([]);
  const [activeAspectFilter, setActiveAspectFilter] = useState(
    _.get(filterJson, "activeAspectFilter", [])
  );

  // States for Grid System
  const [gridCol, setGridCol] = useState(2);
  const [gridSlider, setGridSlider] = useState(girdDefualtValue);

  // States for Sortings
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortValue, setSortValue] = useState(
    _.get(filterJson, "sortValue", sortingDefualValue)
  );
  const [buyerPostalCode, setBuyerPostalCode] = useState("");

  // States for Search Results
  const [searchResult, setSearchResult] = useState([]);
  const [searchResultItemList, setSearchResultItemList] = useState([]);

  // States for Loaders
  const [fetchingSearchResult, setFetchingSearchResult] = useState(true);
  const [saveSearchInProcess, setSaveSearchInProcess] = useState(false);
  const [notificationInProcess, setNotificationInProcess] = useState(false);

  // States for filters
  const [descriptionSearch, setDescriptionSearch] = useState(
    _.get(filterJson, "descriptionSearch", false)
  );
  const [listingType, setListingType] = useState(
    _.get(filterJson, "listingType", {})
  );
  const [conditionList, setConditionList] = useState(
    _.get(filterJson, "conditionList", {})
  );
  const [topRatedSellerOnly, setTopRatedSellerOnly] = useState(
    _.get(filterJson, "topRatedSellerOnly", false)
  );
  const [freeShippingOnly, setFreeShippingOnly] = useState(
    _.get(filterJson, "freeShippingOnly", false)
  );
  const [returnsAcceptedOnly, setReturnsAcceptedOnly] = useState(
    _.get(filterJson, "returnsAcceptedOnly", false)
  );
  const [localPickupOnly, setLocalPickupOnly] = useState(
    _.get(filterJson, "localPickupOnly", false)
  );
  const [endIn24Hr, setEndIn24Hr] = useState(
    _.get(filterJson, "endIn24Hr", false)
  );
  const [startFrom7D, setStartFrom7D] = useState(
    _.get(filterJson, "startFrom7D", false)
  );
  const [authorizedSellerOnly, setAuthorizedSellerOnly] = useState(
    _.get(filterJson, "authorizedSellerOnly", false)
  );
  const [minPriceFilter, setMinPriceFilter] = useState(
    _.get(filterJson, "minPriceFilter", "")
  );
  const [maxPriceFilter, setMaxPriceFilter] = useState(
    _.get(filterJson, "maxPriceFilter", "")
  );

  const theme = useTheme();
  const classes = style();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const { searchKey } = useParams();
  const search = useLocation().search;
  let type = "keyword";
  const queryType = new URLSearchParams(search).get("type");
  if (queryType) type = queryType;

  // Rearrange grid item when window width will change
  useEffect(() => {
    if (isWidthUp("md", props.width)) {
      setGridSlider({
        values: girdDefualtValue.values,
        getValue: girdDefualtValue.getValue,
      });
    } else if (isWidthUp("sm", props.width)) {
      setGridSlider({
        values: [{ value: 1 }, { value: 2 }, { value: 3 }],
        getValue: [4, 6, 12],
      });
      if (gridCol < 4) setGridCol(4);
    } else if (isWidthUp("xs", props.width)) {
      setGridSlider({
        values: [{ value: 1 }, { value: 2 }],
        getValue: [6, 12],
      });
      if (gridCol < 4) setGridCol(4);
    }
  }, [props.width]);

  const getSearchQueryMappingObj = () => {
    return {
      searchKey,
      activeCategoryId,
      activeAspectFilter,
      sortValue,
      descriptionSearch,
      listingType,
      conditionList,
      topRatedSellerOnly,
      freeShippingOnly,
      returnsAcceptedOnly,
      localPickupOnly,
      endIn24Hr,
      startFrom7D,
      authorizedSellerOnly,
      minPriceFilter,
      maxPriceFilter,
    };
  };

  // API call for save search click
  const handleSaveClick = async (mode = -1) => {
    if (!saveSearchInProcess) {
      setSaveSearchInProcess(true);
      if (saveState) {
        await deleteSubscription({
          subscription_id: subscriptionId,
        });
        toast.success("Search removed successfully");
        setSaveState(false);
        setIsEmailTrue(false);
        setIsSmsTrue(false);
      } else {
        try {
          const queryMapping = getSearchQueryMappingObj();
          let res = await saveSearch({
            keyword: searchKey,
            username: _.get(auth, "userData.user.username", ""),
            active: mode === -1 ? 0 : 1,
            mode: mode === -1 ? 0 : mode,
            search_query: {
              ...outerPayload,
              paginationInput: {
                entriesPerPage: 20,
                pageNumber: 1,
              },
            },
            search_query_mapping: queryMapping,
          });
          if (_.get(res, "data.tracker", -1) !== -1) {
            if (saveState) {
              toast.success("Search removed successfully");
              setIsEmailTrue(false);
              setIsSmsTrue(false);
            } else {
              setSubscriptionId(_.get(res, "data.subscription_id", -1));
              if (mode === 0) {
                setIsEmailTrue(true);
              } else if (mode === 1) {
                setIsSmsTrue(true);
              } else if (mode === 4) {
                setIsEmailTrue(true);
                setIsSmsTrue(true);
              }
              toast.success("Search saved successfully");
            }
            setSaveState(!saveState);
          } else {
            toast.error(_.get(res, "data.message", ""));
          }
        } catch (err) {
          toast.error(_.get(err, "response.data.message", ""));
        }
      }
      setSaveSearchInProcess(false);
    }
  };

  // API call for enable/disable notification
  const handleNotificationClick = async (payload = "") => {
    if (!notificationInProcess) {
      setNotificationInProcess(true);
      if (saveState) {
        let mode = 0;
        let state = 1;
        let sms = isSmsTrue;
        let email = isEmailTrue;
        if (payload === "sms") {
          sms = !isSmsTrue;
        } else {
          email = !isEmailTrue;
        }
        if (sms && email) {
          mode = 4;
        } else if (sms) {
          mode = 1;
        } else if (email) {
          mode = 0;
        } else {
          state = 0;
        }
        const res = await editSaveSearch({
          subscription_id: subscriptionId,
          active: state,
          mode: mode,
        });
        if (_.get(res, "data.subscription_id", -1) !== -1) {
          if (_.get(res, "data.enabled", false)) {
            setIsSmsTrue(
              _.get(res, "data.delivery_mode", -1) === 1 ||
                _.get(res, "data.delivery_mode", -1) === 4
            );
            setIsEmailTrue(
              _.get(res, "data.delivery_mode", -1) === 0 ||
                _.get(res, "data.delivery_mode", -1) === 4
            );
          } else {
            setIsSmsTrue(false);
            setIsEmailTrue(false);
          }
          toast.success("notification preferences updated successfully");
        } else {
          toast.error(_.get(res, "data.message", ""));
        }
      } else {
        let mode = 0;
        if (payload === "sms") {
          mode = 1;
        }
        handleSaveClick(mode);
      }
      setNotificationInProcess(false);
    }
  };

  // API call for get all saved search to identify current search in saved or not
  const getAllSaveSearch = async () => {
    setSaveSearchLoading(true);
    let res = await getAllSubscription({
      username: _.get(auth, "userData.user.username", ""),
    }).catch((e) => {});
    if (res && res.data) {
      setAllSaveSearch(res.data);
    }
    setSaveSearchLoading(false);
  };

  // API call for search
  const getAndSetSearchResult = async (init = false) => {
    setFetchingSearchResult(true);
    let payload = {
      keywords: type === "keyword" ? searchKey : "",
      paginationInput: {
        entriesPerPage: 20,
        pageNumber: init
          ? 1
          : +_.get(searchResult, "paginationOutput.pageNumber", 0) + 1,
      },
      outputSelector: [
        "CategoryHistogram",
        "AspectHistogram",
        "PictureURLSuperSize",
      ],
      sortOrder: sortValue.value,
      aspectFilter: activeAspectFilter,
      itemFilter: [
        {
          name: "HideDuplicateItems",
          value: true,
        },
      ],
    };
    outerPayload = {};

    // Filter - Category
    if (activeCategoryId !== -1) {
      payload = { ...payload, categoryId: activeCategoryId };
    }

    // Filter - Include Description
    if (descriptionSearch) {
      payload = { ...payload, descriptionSearch: descriptionSearch };
    }

    // Filter - Listing Type
    let listTypeArr = [];
    _.forEach(listingType, function (value, key) {
      if (value) listTypeArr.push(key);
    });
    if (sortValue.value === "BidCountMost") {
      listTypeArr.push("Auction");
    }
    if (listTypeArr.length > 0) {
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "ListingType",
            value: listTypeArr,
          },
        ],
      };
    }

    // Filter - Condition
    let conditionListArr = [];
    _.forEach(conditionList, function (value, key) {
      if (value) conditionListArr.push(key);
    });
    if (conditionListArr.length > 0) {
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "Condition",
            value: conditionListArr,
          },
        ],
      };
    }

    // seller
    if (type === "seller")
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "Seller",
            value: searchKey,
          },
        ],
      };

    // Filter - Top Rated Seller Only
    if (topRatedSellerOnly)
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "TopRatedSellerOnly",
            value: topRatedSellerOnly,
          },
        ],
      };

    // Filter - Free Shipping Only
    if (freeShippingOnly)
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "FreeShippingOnly",
            value: freeShippingOnly,
          },
        ],
      };

    // Filter - Local Pickup Only
    if (localPickupOnly)
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "LocalPickupOnly",
            value: localPickupOnly,
          },
        ],
      };

    // Filter - End In 24Hr
    if (endIn24Hr)
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "EndTimeTo",
            value: new Date(addHours(new Date(), 24)).toISOString(),
          },
        ],
      };

    // Filter - Start From 7D
    if (startFrom7D)
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "StartTimeFrom",
            value: new Date(addDays(new Date(), 7)).toISOString(),
          },
        ],
      };

    // Filter - Authorized Seller Only
    if (authorizedSellerOnly)
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "AuthorizedSellerOnly",
            value: authorizedSellerOnly,
          },
        ],
      };

    // Filter - Min Price
    if (minPriceFilter !== "")
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "MinPrice",
            value: +minPriceFilter,
          },
        ],
      };

    // Filter - Max Price
    if (maxPriceFilter !== "")
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "MaxPrice",
            value: +maxPriceFilter,
          },
        ],
      };

    // Sorting - Extra props
    if (sortValue.value === "DistanceNearest") {
      payload = { ...payload, buyerPostalCode: buyerPostalCode };
    }

    if (returnsAcceptedOnly)
      payload = {
        ...payload,
        itemFilter: [
          ...payload.itemFilter,
          {
            name: "ReturnsAcceptedOnly",
            value: returnsAcceptedOnly,
          },
        ],
      };

    outerPayload = payload;

    const res = await searchService(payload);
    if (res.data && outerPayload === payload) {
      setSearchResult(_.get(res, "data", {}));
      setCategoryFilterList(
        _.get(res, "data.categoryHistogramContainer.categoryHistogram", [])
      );
      setAspectList(_.get(res, "data.aspectHistogramContainer.aspect", []));
      if (init) {
        setSearchResultItemList([..._.get(res, "data.searchResult.item", [])]);
      } else {
        setSearchResultItemList([
          ...searchResultItemList,
          ..._.get(res, "data.searchResult.item", []),
        ]);
      }
    }
    setFetchingSearchResult(false);
  };

  const updateQueryString = () => {
    const query = getSearchQueryMappingObj();
    navigate({
      search: `?filter=` + JSON.stringify(query),
    });
  };

  // Clear data and call for search API when search key, category or sorting value change
  useEffect(() => {
    setSearchResultItemList([]);
    setFetchingSearchResult(true);
    getAndSetSearchResult(true);
    updateQueryString();
  }, [
    searchKey,
    activeCategoryId,
    activeAspectFilter,
    sortValue,
    descriptionSearch,
    listingType,
    conditionList,
    topRatedSellerOnly,
    freeShippingOnly,
    returnsAcceptedOnly,
    localPickupOnly,
    endIn24Hr,
    startFrom7D,
    authorizedSellerOnly,
    minPriceFilter,
    maxPriceFilter,
  ]);

  useEffect(() => {
    getAllSaveSearch();
  }, [searchKey]);

  useEffect(() => {
    const filteredData = _.filter(
      allSaveSearch,
      (item) =>
        _.toLower(_.get(item, "tracker_name", "-")) === _.toLower(searchKey)
    );
    if (filteredData.length > 0) {
      if (
        _.toLower(_.get(filteredData, "[0].tracker_name", false)) ===
          _.toLower(searchKey) &&
        _.isEqual(
          _.get(filteredData, "[0].search_query_mapping", {}),
          getSearchQueryMappingObj()
        )
      ) {
        setSaveState(true);
        setSubscriptionId(_.get(filteredData, "[0].subscription_id", -1));
        if (_.get(filteredData, "[0].enabled", false)) {
          setIsSmsTrue(
            _.get(filteredData, "[0].delivery_mode", -1) === 1 ||
              _.get(filteredData, "[0].delivery_mode", -1) === 4
          );
          setIsEmailTrue(
            _.get(filteredData, "[0].delivery_mode", -1) === 0 ||
              _.get(filteredData, "[0].delivery_mode", -1) === 4
          );
        } else {
          setIsSmsTrue(false);
          setIsEmailTrue(false);
        }
      } else {
        setSaveState(false);
        setIsSmsTrue(false);
        setIsEmailTrue(false);
      }
    } else {
      setSaveState(false);
      setIsSmsTrue(false);
      setIsEmailTrue(false);
    }
  }, [
    allSaveSearch,
    searchKey,
    activeCategoryId,
    activeAspectFilter,
    sortValue,
    descriptionSearch,
    listingType,
    conditionList,
    topRatedSellerOnly,
    freeShippingOnly,
    returnsAcceptedOnly,
    localPickupOnly,
    endIn24Hr,
    startFrom7D,
    authorizedSellerOnly,
    minPriceFilter,
    maxPriceFilter,
  ]);

  // Sorting menu actions
  const handleSortMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSortChange = (sortValue) => {
    if (sortValue.value !== "DistanceNearest") {
      setBuyerPostalCode("");
    }
    setSortValue(sortValue);
    setAnchorEl(null);
  };

  // UI Elements ==============================================================================
  const BtnSorting = (
    <>
      <Button
        style={{
          border: "1px solid",
          borderRadius: "5px",
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          margin: "10px",
          marginTop: "0px",
        }}
        onClick={handleSortMenuOpen}
        width={185}
        minWidth={185}
      >
        <SortIcon fontSize="small" />
        &nbsp;
        <Typography variant="body2" fontWeight={500}>
          Sort {sortValue.display}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSortMenuClose}
        width={185}
        style={{ minWidth: 185 }}
        keepMounted
        className={classes.customWidth}
      >
        <MenuItem
          onClick={() =>
            handleSortChange({
              display: "Best Match",
              value: "BestMatch",
            })
          }
        >
          Best Match
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSortChange({
              display: "Newly Listed",
              value: "StartTimeNewest",
            })
          }
        >
          Newly Listed
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSortChange({
              display: "Ending Soon",
              value: "EndTimeSoonest",
            })
          }
        >
          Ending Soon
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSortChange({
              display: "Price Lowest",
              value: "PricePlusShippingLowest",
            })
          }
        >
          Price Lowest
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSortChange({
              display: "Price Highest",
              value: "PricePlusShippingHighest",
            })
          }
        >
          Price Highest
        </MenuItem>
        <MenuItem>
          <Box width="185px">
            <Box>Distance: nearest first</Box>
            <Box mt={1} display="flex">
              <TextField
                placeholder="Zip Code"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ maxWidth: "95px" }}
                width="95px"
                variant="outlined"
                size="small"
                value={buyerPostalCode}
                onChange={(e) => {
                  setBuyerPostalCode(e.target.value);
                }}
              />
              <IconButton
                style={{
                  background: theme.palette.primary.main,
                  borderRadius: "5px",
                  padding: "5px",
                  margin: "5px",
                  marginLeft: "15px",
                }}
                onClick={() =>
                  handleSortChange({
                    display: "Distance: nearest first",
                    value: "DistanceNearest",
                  })
                }
              >
                <IconArrowBigRight
                  color={theme.palette.primary.main}
                  width="20"
                  height="20"
                  fill={"white"}
                />
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSortChange({
              display: "Most Bids",
              value: "BidCountMost",
            })
          }
        >
          Most Bids
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSortChange({
              display: "Most Watched",
              value: "WatchCountDecreaseSort",
            })
          }
        >
          Most Watched
        </MenuItem>
      </Menu>
    </>
  );

  const SearchKeyButtonView = (
    <>
      <Button
        style={{
          border: "1px solid",
          borderRadius: "3px",
          color: theme.palette.primary.main,
          margin: "10px",
          marginTop: "0px",
          cursor: "unset",
          pointerEvents: "none",
        }}
      >
        <Typography variant="h6">
          {(type === "seller" ? "Seller : " : "") + searchKey}
        </Typography>
        &nbsp;
        <CloseIcon
          className="cursorPointer"
          style={{ pointerEvents: "all" }}
          onClick={() => navigate(paths.home)}
        />
      </Button>
      {type === "seller" && (
        <Button
          color="primary"
          variant="contained"
          style={{
            border: "0px",
            borderRadius: "3px",
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: "10px",
            marginTop: "0px",

            pointerEvents: "none",
          }}
        >
          <Typography variant="h6">Contact Seller</Typography>
        </Button>
      )}
    </>
  );

  const btnNotification = (
    <>
      <Button
        style={{
          border: "1px solid",
          borderRadius: "3px",
          color: theme.palette.error.main,
          margin: "10px",
          marginTop: "0px",
        }}
        onClick={() => handleNotificationClick("email")}
      >
        {notificationInProcess || saveSearchLoading ? (
          <Box style={{ margin: "4px 4px 0px 4px" }}>
            <CircularProgress
              size={16}
              style={{ color: theme.palette.error.main }}
            />
          </Box>
        ) : isEmailTrue ? (
          <EmailIcon />
        ) : (
          <EmailOutlinedIcon />
        )}
        &nbsp;
        <Typography variant="h6">Email alerts</Typography>
      </Button>
      <NotificationPreferencesDialog>
        <Button
          style={{
            border: "1px solid",
            borderRadius: "3px",
            color: theme.palette.error.main,
            margin: "10px",
            marginTop: "0px",
          }}
          onClick={() => {
            if (_.get(auth, "userData.user.phone_verified", false))
              handleNotificationClick("sms");
          }}
        >
          {notificationInProcess || saveSearchLoading ? (
            <Box style={{ margin: "4px 4px 0px 4px" }}>
              <CircularProgress
                size={16}
                style={{ color: theme.palette.error.main }}
              />
            </Box>
          ) : isSmsTrue ? (
            <TextsmsIcon />
          ) : (
            <TextsmsOutlinedIcon />
          )}
          &nbsp;
          <Typography variant="h6">SMS alerts</Typography>
        </Button>
      </NotificationPreferencesDialog>
    </>
  );
  const btnSaveSearch = (
    <Button
      style={{
        border: "1px solid",
        borderRadius: "3px",
        color: theme.palette.primary.main,
        margin: "10px",
        marginTop: "0px",
      }}
    >
      {saveSearchInProcess || saveSearchLoading ? (
        <Box style={{ margin: "4px 4px 0px 4px" }}>
          <CircularProgress size={16} />
        </Box>
      ) : saveState ? (
        <FavoriteIcon />
      ) : (
        <FavoriteBorderIcon />
      )}
      &nbsp;
      <Typography variant="h6">Save this search</Typography>
    </Button>
  );

  const TopButtonListView = (
    <Box>
      {btnNotification}
      {saveState ? (
        <ConfirmationDialog
          dialogTitle={`Are you sure want to remove ${searchKey} from the saved search?`}
          dialogText={""}
          ConfirmCallBack={() => handleSaveClick()}
        >
          {btnSaveSearch}
        </ConfirmationDialog>
      ) : (
        <span onClick={() => handleSaveClick()}>{btnSaveSearch}</span>
      )}

      <Box display="inline-flex">
        <Box display="block">
          <Box
            display="inline-flex"
            style={{
              color: theme.palette.text.secondary,
              margin: "10px",
              marginTop: "0px",
            }}
          >
            <Typography variant="h6">
              {_.get(searchResult, "paginationOutput.totalEntries", "0")}{" "}
              results
            </Typography>
          </Box>
          {BtnSorting}
          <Box
            display="inline-flex"
            style={{
              marginBottom: "0px",
              width: "200px",
              height: "28px",
              position: "relative",
            }}
          >
            <Grid container spacing={1} className="pos-abs">
              <Grid item>
                <AppsIcon fontSize="large" />
              </Grid>
              <Grid item xs>
                <GridSlider
                  value={gridSlider.getValue.indexOf(gridCol) + 1}
                  min={1}
                  max={gridSlider.values.length}
                  aria-labelledby="discrete-slider-restrict"
                  step={null}
                  valueLabelDisplay="off"
                  marks={gridSlider.values}
                  onChange={(event, value) => {
                    setGridCol(gridSlider.getValue[value - 1]);
                  }}
                />
              </Grid>
              <Grid item>
                <Box sx={{ ml: "5px" }}>
                  <IconLayoutGrid fill="#172b4d" width="35" height="35" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const CategoryView = !fetchingSearchResult && (
    <CategoriesList
      activeCategoryId={activeCategoryId}
      categoryFilterList={categoryFilterList}
      setActiveCategoryId={setActiveCategoryId}
    />
  );

  const SearchResultView = searchResultItemList.length > 0 && (
    <ProductList
      searchKey={searchKey}
      searchResult={searchResult}
      newListData={searchResultItemList}
      gridCol={gridCol}
    />
  );

  const loader = fetchingSearchResult && (
    <Box mb={5} mt={searchResultItemList.length > 0 ? 0 : 5} textAlign="center">
      <CircularProgress />
      <Box>Hang on tight we are loading great deals for you...</Box>
    </Box>
  );

  const emptyView = !fetchingSearchResult &&
    _.get(searchResult, "paginationOutput.pageNumber", "0") ===
      _.get(searchResult, "paginationOutput.totalPages", "0") &&
    _.get(searchResult, "paginationOutput.totalEntries", "0") === "0" && (
      <Box m={3}>
        <Typography variant="h6">
          Your search - {searchKey} - did not match any items
        </Typography>
        <Box mt={1}>
          Suggestions:
          <Box ml={5}>
            <ul>
              <li>Try fewer keywords.</li>
              <li>Try different keywords.</li>
              <li>Try more general keywords.</li>
              <li>Make sure all words are spelled correctly.</li>
              <li>
                Try including descriptions in the search, click the "Include
                description" button in side menu.
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    );

  const BtnSeeMoreView = !fetchingSearchResult &&
    _.get(searchResult, "paginationOutput.pageNumber", "0") !==
      _.get(searchResult, "paginationOutput.totalPages", "0") && (
      <Box textAlign="center" mb={3}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => getAndSetSearchResult(false)}
        >
          <Typography noWrap variant="subtitle2" className="m-w-2x">
            See More...
          </Typography>
        </Button>
      </Box>
    );

  const SimilarSearchView = false &&
    (searchResultItemList.length > 0 || !fetchingSearchResult) && (
      <>
        <Divider />
        <Box p={1} pl={0} display="flex" mt={2} mb={1}>
          <Typography
            variant="h5"
            onClick={() =>
              navigate(paths.search.replace(":searchKey", "1993 derek jeter"))
            }
            color="textPrimary"
          >
            <span>Result matching fewer words:&nbsp;</span>
            <span
              style={{ color: theme.palette.primary.main }}
              className="searchLink"
            >
              1993 derek jeter
            </span>
          </Typography>
        </Box>
        <ProductList gridCol={gridCol} />
      </>
    );

  return (
    <>
      <FilterMenu
        descriptionSearch={descriptionSearch}
        setDescriptionSearch={setDescriptionSearch}
        listingType={listingType}
        setListingType={setListingType}
        conditionList={conditionList}
        setConditionList={setConditionList}
        topRatedSellerOnly={topRatedSellerOnly}
        setTopRatedSellerOnly={setTopRatedSellerOnly}
        freeShippingOnly={freeShippingOnly}
        setFreeShippingOnly={setFreeShippingOnly}
        returnsAcceptedOnly={returnsAcceptedOnly}
        setReturnsAcceptedOnly={setReturnsAcceptedOnly}
        localPickupOnly={localPickupOnly}
        setLocalPickupOnly={setLocalPickupOnly}
        endIn24Hr={endIn24Hr}
        setEndIn24Hr={setEndIn24Hr}
        startFrom7D={startFrom7D}
        setStartFrom7D={setStartFrom7D}
        authorizedSellerOnly={authorizedSellerOnly}
        setAuthorizedSellerOnly={setAuthorizedSellerOnly}
        minPriceFilter={minPriceFilter}
        maxPriceFilter={maxPriceFilter}
        setMinPriceFilter={setMinPriceFilter}
        setMaxPriceFilter={setMaxPriceFilter}
        aspectList={aspectList}
        activeAspectFilter={activeAspectFilter}
        setActiveAspectFilter={setActiveAspectFilter}
        fetchingSearchResult={fetchingSearchResult}
      >
        <Box>
          {SearchKeyButtonView}
          {TopButtonListView}
          {CategoryView}
          {SearchResultView}
          {loader}
          {emptyView}
          {BtnSeeMoreView}
          {SimilarSearchView}
        </Box>
      </FilterMenu>
    </>
  );
};

export default withWidth()(SearchResult);
