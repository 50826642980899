import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@material-ui/core";
import { mailActivationService } from "services/authServices";
import _ from "lodash";
import { paths } from "routes";

const MailActivation = () => {
  const { uid, token } = useParams();
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    try {
      const response = await mailActivationService({ uid, token });
      setResponseData(_.get(response, "data", {}));
    } catch (err) {
      setResponseData(
        _.get(err, "response.data", {
          status: 400,
          message: "Invalid Token",
        })
      );
    }
    setLoading(false);
  }, [mailActivationService, uid, token]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container
          maxWidth="md"
          textAlign="center"
          sx={{ px: "0px", py: "80px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h1"
                textAlign="center"
                style={{
                  color:
                    _.get(responseData, "status", 400) === 200
                      ? "#28a745"
                      : "#dc3545",
                }}
              >
                {_.get(responseData, "status", 400) === 200
                  ? "Your account has been verified!"
                  : "Verification link expired"}
              </Typography>
            )}
          </Box>
          {_.get(responseData, "status", 400) !== 200 && (
            <Box textAlign="center" mb={2}>
              <Link
                color="textSecondary"
                component={RouterLink}
                to={paths.resendMailConfirm}
                variant="h6"
              >
                Resend verification link
              </Link>
            </Box>
          )}
          <Box textAlign="center">
            <Link
              color="textSecondary"
              component={RouterLink}
              to={paths.login}
              variant="body2"
            >
              Go to Login
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MailActivation;
