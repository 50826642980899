import { useContext, useEffect } from "react";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CogIcon from "icons/Cog";
import UserIcon from "icons/User";
import Scrollbar from "../Scrollbar";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import { logoutService } from "services/authServices";
import { useState } from "react";
import { paths } from "routes";

const Sidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { auth, changeAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleLogout = async () => {
    setLoading(true);
    await logoutService();
    changeAuth({ isLoggedIn: false, userData: {} });
    navigate(paths.login);
    setLoading(false);
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden mdUp>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            Logo Here
          </Box>
        </Hidden>
        <Divider />
        <Box sx={{ p: 2 }}>
          {_.get(auth, "isLoggedIn", false) ? (
            <>
              <Box sx={{ mt: 2 }}>
                <MenuItem
                  component={RouterLink}
                  to={paths.setting}
                  onClick={onMobileClose}
                >
                  <ListItemIcon>
                    <CogIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        Settings
                      </Typography>
                    }
                  />
                </MenuItem>
              </Box>
              <Box sx={{ p: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleLogout}
                  variant="outlined"
                >
                  {loading ? <CircularProgress size={24} /> : "Logout"}
                </Button>
              </Box>
            </>
          ) : (
            <Box sx={{ mt: 2 }}>
              <MenuItem component={RouterLink} to={paths.login}>
                <ListItemIcon>
                  <UserIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="subtitle2">
                      Login
                    </Typography>
                  }
                />
              </MenuItem>
              <MenuItem component={RouterLink} to={paths.register}>
                <ListItemIcon>
                  <CogIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="subtitle2">
                      Register
                    </Typography>
                  }
                />
              </MenuItem>
            </Box>
          )}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="up"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: "100%",
              zIndex: 1201,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default Sidebar;
