import { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import { toast } from "react-toastify";
import { validateEmail, validatePhoneNumber } from "utils";
import {
  changeEmail,
  confirmPhoneNumber,
  resendEmailConfirmation,
  sendOtp,
  userService,
} from "services/authServices";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CPhoneInput from "./PhoneNumber";
import { SlideTransition } from "components/SlideTransition";

const AccountNotificationsSettings = (props) => {
  const [email, setEmail] = useState("");
  const [emailChangeLoading, setEmailChangeLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneChangeLoading, setPhoneChangeLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const [otpOpen, setOtpOpen] = useState(false);
  const [otp, setOtp] = useState("");

  const { auth, changeAuth } = useContext(AuthContext);
  const [howOften, setHowOften] = useState("0 11 * * *");
  const username = _.get(auth, "userData.user.username", "");
  const userMail = _.get(auth, "userData.user.email", "") || "";
  const phone_verified = _.get(auth, "userData.user.phone_verified", false);
  const email_verified = _.get(auth, "userData.user.email_verified", false);
  const phone_number = _.get(auth, "userData.user.phone_number", "") || "";

  useEffect(() => {
    setEmail(_.get(auth, "userData.user.email", "") || "");
    setPhone(_.get(auth, "userData.user.phone_number", "") || "");
  }, [auth]);

  useEffect(() => {
    if (validateEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please enter valid email");
    }
  }, [email]);

  useEffect(() => {
    if (validatePhoneNumber(phone) || phone === "") {
      setPhoneError("");
    } else {
      setPhoneError(
        "Please enter valid phone number with country code (ex. +19876543210,+919876543210)"
      );
    }
  }, [phone]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const stripeParams = params.get("stripe");
    if (stripeParams === "paid") {
      toast.success("Your credit successfully credited");
    }
  }, []);

  const getAndSetUserData = async () => {
    let res = await userService();
    changeAuth({
      ...auth,
      userData: {
        ...auth.userData,
        user: { ...auth.userData.user, ..._.get(res, "data", {}) },
      },
    });
  };

  const handleMailConfirmation = async () => {
    setEmailChangeLoading(true);
    let res = await resendEmailConfirmation({ username: username });
    if (_.get(res, "data.status", "") === 200) {
      toast.success(
        "a verification link has been successfully sent on your registered mail id, please check your inbox"
      );
    } else {
      toast.error(
        "Sorry, we are unable to send you the verification mail, please try again later"
      );
    }
    setEmailChangeLoading(false);
  };

  const handleEmailChange = async () => {
    setEmailChangeLoading(true);
    let res = await changeEmail(email);
    if (_.get(res, "data.status", "") === "success") {
      await getAndSetUserData();
      toast.success(
        "Your email has been updated successfully, we have sent you a verification link please check your inbox"
      );
    }
    setEmailChangeLoading(false);
  };

  const handlePhoneChange = async () => {
    setPhoneChangeLoading(true);
    let res = await sendOtp(phone).catch((err) => console.error(err));
    if (_.get(res, "data.status", "") === "success") {
      await getAndSetUserData();
      setOtp("");
      setOtpOpen(true);
    } else {
      toast.error(
        "Sorry, we are unable to send you the verification code, please verified your entered number and try again"
      );
    }
    setPhoneChangeLoading(false);
  };

  const validateOtp = async () => {
    setPhoneChangeLoading(true);
    let res = await confirmPhoneNumber(phone, otp).catch((err) =>
      console.error(err)
    );
    if (_.get(res, "data.message", "") === "Phone Verified") {
      await getAndSetUserData();
      toast.success("Your phone number has been verified successfully");
      setOtpOpen(false);
      setOtp("");
    } else {
      toast.error(_.get(res, "data.message", ""));
    }
    setPhoneChangeLoading(false);
  };

  const UiOtpDialogBox = (
    <Dialog
      open={otpOpen}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={() => {
        setOtpOpen(false);
        setOtp("");
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>OTP</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We have sent 6 digit verification code on your phone please enter it
          here
          <Box mt={1}>
            <TextField
              fullWidth
              value={otp}
              onChange={(e) => {
                if (!_.isNaN(+e.target.value) && e.target.value.length <= 6)
                  setOtp(e.target.value);
              }}
            />
          </Box>
          <Box mt={1}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={validateOtp}
              disabled={otp.length !== 6}
            >
              {phoneChangeLoading ? (
                <CircularProgress style={{ color: "white" }} size={26} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={() => setOtpOpen(false)} color="primary">
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Card>
        <CardHeader title="Notifications" />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid item md={12} xs={12}>
              <Box mb={4}>
                <TextField
                  fullWidth
                  label="Email"
                  name="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  error={emailError.length > 0}
                  helperText={emailError}
                />
                {userMail === email && !email_verified && (
                  <>
                    <Box flex>
                      <FormHelperText style={{ display: "inline-block" }} error>
                        &nbsp;&nbsp; email not verified!&nbsp;
                      </FormHelperText>
                      <FormHelperText style={{ display: "inline-block" }}>
                        <span
                          className="cursorPointer"
                          style={{
                            color: "black",
                            fontWeight: 400,
                            textDecoration: emailChangeLoading
                              ? ""
                              : "underline",
                          }}
                          onClick={handleMailConfirmation}
                        >
                          {emailChangeLoading ? (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <CircularProgress size={12} />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                          ) : (
                            "Resend verification link"
                          )}
                        </span>{" "}
                      </FormHelperText>
                    </Box>
                  </>
                )}
                {userMail !== email && (
                  <Box textAlign="left" flex mt={1} pb={1}>
                    <Button
                      color="primary"
                      onClick={handleEmailChange}
                      variant="contained"
                      disabled={emailChangeLoading || emailError.length > 0}
                    >
                      Change Email
                    </Button>
                    <Box display="inline" ml={1}>
                      <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="text"
                        onClick={() => setEmail(userMail)}
                      >
                        cancel
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box mb={3}>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={phone}
                  onChange={setPhone}
                  inputComponent={CPhoneInput}
                />
                {phone_number !== phone && (
                  <Box textAlign="left" flex mt={1} pb={1}>
                    <Button
                      color="primary"
                      onClick={handlePhoneChange}
                      variant="contained"
                      disabled={phoneChangeLoading || phoneError.length > 0}
                    >
                      Verify &&nbsp;
                      {_.isNull(phone_number) || _.isEmpty(phone_number)
                        ? "Add"
                        : "Change"}
                      &nbsp;Phone Number
                    </Button>
                    <Box display="inline" ml={1}>
                      <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="text"
                        onClick={() => setPhone(phone_number)}
                      >
                        cancel
                      </Button>
                    </Box>
                  </Box>
                )}
                {phone_number === phone && !phone_verified && phone !== "" && (
                  <>
                    <Box flex>
                      <FormHelperText style={{ display: "inline-block" }} error>
                        &nbsp;&nbsp; phone number not verified!&nbsp;
                      </FormHelperText>
                      <FormHelperText style={{ display: "inline-block" }}>
                        <span
                          className="cursorPointer"
                          onClick={handlePhoneChange}
                          style={{
                            color: "black",
                            fontWeight: 400,
                            textDecoration: phoneChangeLoading
                              ? ""
                              : "underline",
                          }}
                        >
                          {phoneChangeLoading ? (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <CircularProgress size={12} />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                          ) : (
                            "Resend verification code"
                          )}
                        </span>{" "}
                      </FormHelperText>
                    </Box>
                  </>
                )}
              </Box>
              <Box display="flex">
                <Typography
                  variant="subtitle1"
                  style={{ lineHeight: "36px", marginRight: "15px" }}
                >
                  Credits: {_.get(auth, "userData.user.credits", "-")}
                </Typography>
              </Box>
            </Grid>
            {false && (
              <Grid item md={4} sm={6} xs={12}>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle2"
                >
                  Digest Notification
                </Typography>
                <Box display="flex" mb={1} style={{ paddingTop: "5px" }}>
                  <Typography variant="body2" lineHeight="40px" width="80px">
                    How often
                  </Typography>
                  <Select
                    variant="outlined"
                    value={howOften}
                    size="small"
                    style={{ width: "250px" }}
                    onChange={(event) => {
                      setHowOften(event.target.value);
                    }}
                  >
                    <MenuItem value={"0 11 * * *"}>At most once a day</MenuItem>
                    <MenuItem value={"0 11 * * */6"}>
                      At most once a week
                    </MenuItem>
                  </Select>
                </Box>
                <div>
                  <FormControlLabel
                    control={<Checkbox color="primary" defaultChecked />}
                    label="Email"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={<Checkbox color="primary" defaultChecked />}
                    disabled={!phone_verified}
                    label="Text message"
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {UiOtpDialogBox}
    </>
  );
};

export default AccountNotificationsSettings;
