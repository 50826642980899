import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  ItemGrid: {
    "& .wrapper:hover": {
      background: "rgba(2,117,216,0.09)",
    },
    "& .back-hover:hover .item-title": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    "& .back-hover:hover .btn-wrapper": {
      display: "flex",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "7px",
      backgroundColor: "#f5f5f5",
    },

    "&::-webkit-scrollbar": {
      width: "9px",
      backgroundColor: "#f5f5f5",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      "-webkitBoxShadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      backgroundColor: "#d8dce4",
    },
  },
  ProductImages: {
    position: "relative",
    overflow: "hidden",
    "& .btn-wrapper": {
      display: "none",
      position: "absolute",
      bottom: 0,
      right: 0,
      color: "white",
    },
    "& .count": {
      background: "rgba(0,0,0,0.5)",
      height: "24px",
    },
    "& .nav-btns": {
      background: "rgba(0,0,0,0.5)",
      cursor: "pointer",
    },
    "& img": {
      minHeight: 0,
      minWidth: 0,
      display: "block",
    },
  },
}));
